import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import logo1 from "../../assets/HealthCare2u_Logo.svg";
import logo2 from "../../assets/PCGS_Logo.png";
import carousel from "../../assets/carousel.png";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../common/themeContext";

const Landing = () => {
  const navigate = useNavigate();

  const { toggleTheme, theme } = useContext(ThemeContext);


  useEffect(() => {
    if (theme === "healthCare") {
      const button = document.getElementById('logo1'); 
      if (button) {
        button.focus();
      }
    } else if (theme === "primeCare") {
      const button = document.getElementById('logo2'); 
      if (button) {
        button.focus();
      }
    }
  }, [theme]);


  const checkedLoggedIn = localStorage.getItem("token")

  useEffect(() => {
    if (checkedLoggedIn) {
      navigate("/dashboard")
    }
  }, [checkedLoggedIn])

  return (
    <Container
      fluid
      className="login-padding text-black hv-100 carousel-health"
    >
      <div className="h-100 dflex gap-4 w-100">
        <div className=" w-x-50 d-flex align-items-center justify-content-center">
          <Carousel className="">
            <Carousel.Item>
              <Image className="h-100 w-100" src={carousel} />
            </Carousel.Item>
            <Carousel.Item>
              <Image className="h-100 w-100" src={carousel} />
            </Carousel.Item>
            <Carousel.Item>
              <Image className="h-100 w-100" src={carousel} />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="w-x-50">
          <div className="d-flex align-items-center justify-content-center h-100 mx-700">
            <div className="align-self-center">
              <h1 className="text-center mb-40 p-color gb fw-36">
                Select to continue
              </h1>
              <div className="mb-40 d-flex gap-3">
                <div>
                  <div className="d-flex align-items-center justify-content-center rounded-3 h-100 box-border">
                    <input
                      type="radio"
                      id="logo1"
                      name="theme"
                      className="box-button bg-transparent overflow-hidden border-0"
                      onChange={toggleTheme}
                      checked={theme === "healthCare"}
                    />
                    <label htmlFor="logo1">
                      <img className="" src={logo1} alt="Logo1" />
                    </label>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-center rounded-3 h-100 box-border">
                    <input
                      type="radio"
                      id="logo2"
                      name="theme"
                      className="box-button bg-transparent overflow-hidden border-0 h-100"
                      onChange={toggleTheme}
                      checked={theme === "primeCare"}
                    />
                    <label htmlFor="logo2">
                      <img className="" src={logo2} alt="Logo2" />
                    </label>
                  </div>
                </div>
              </div>

              <button
                onClick={() => navigate("/login")}
                className="w-100 sbg border-0 py3 fw-18 gbo continuebtn"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Landing;
