import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio } from "antd";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loder/loader";
import * as Yup from "yup";
import { getGroupProductFeatureContentHandler } from "../../redux/action/getGroupProductFeatureContent";

const AddDpcExtraGroup = () => {
  const [manageSwitch, setManageSwitch] = useState(false);
  // const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const getRefGroupFeatureId = localStorage.getItem("groupProductFeatureId");
  const getGroupProductFeatureContentSelector = useSelector(
    (state) => state?.getGroupProductFeatureContent
  );
  const getProductFeatureFunction = () => {
    if (getRefGroupFeatureId) {
      let payload = {
        groupProductFeatureId: Number(getRefGroupFeatureId),
      };
      dispatch(getGroupProductFeatureContentHandler(payload));
    }
  };

  useEffect(() => {
    getProductFeatureFunction();
  }, []);

  const englishValidationSchema = Yup.object().shape({
    selectedDiscountType: Yup.string().required(
      "Please select a discount component for English"
    ),
  });

  const spanishValidationSchema = Yup.object().shape({
    selectedDiscountType: Yup.string().required(
      "Por favor seleccione un componente de descuento para Español."
    ),
  });

  const getValidationSchema = (manageSwitch) =>
    Yup.lazy(() =>
      manageSwitch ? spanishValidationSchema : englishValidationSchema
    );

  const handleFormSubmit = (values) => {
    navigate("/add-dpc-discount-group", {
      state: {
        selectedDiscountType: values?.selectedDiscountType,
        manageSwitch: manageSwitch,
        state: state?.fromDpcExtra,
      },
    });
  };

  return (
    <>
      {getGroupProductFeatureContentSelector?.isLoading === true && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.PRODUCTS,
                onClick: () => {
                  navigate("/dashboard");
                  localStorage.removeItem("productId");
                },
              },
              {
                text: BreadCrum.MANAGE_PRODUCTS,
                onClick: () => navigate("/edit-group-product"),
              },
              {
                text: BreadCrum.DPC_EXTRA_LIST,
                onClick: () => navigate("/dpc-extra-group", { state: state }),
              },
              {
                text: BreadCrum.DPC_EXTRA,
                active: true,
              },
            ]}
          />
          <div className="sectionpadding d-block mb-30">
            <Formik
              enableReinitialize
              initialValues={{
                selectedDiscountType: "",
              }}
              validationSchema={getValidationSchema(manageSwitch)}
              onSubmit={(values, { setSubmitting }) => {
                // setSubmitting(false);
                handleFormSubmit(values);
              }}
            >
              {({
                isSubmitting,
                values,
                setFieldValue,
                resetForm,
                errors,
                touched,
              }) => (
                <Form className="login-form">
                  <div className="d-md-flex justify-content-between align-items-center mb-20">
                    <p className="mb-md-0 heedprodct">DPCextra</p>
                  </div>
                  <hr />
                  <div className={"mb-30"}>
                    <div className="mb-30">
                      <label className="form-label">Select Language</label>
                      <div className="switches-container ms-0">
                        <div
                          className={`mempro ${
                            state?.language === "es" ? "disabled" : ""
                          }`}
                          onClick={() => {
                            if (state?.language !== "es") {
                              setManageSwitch(false);
                              resetForm({});
                              // setSelectedDiscountType("");
                            }
                          }}
                        >
                          English
                        </div>
                        <div
                          className={`mempro ${
                            state?.language === "en" ? "disabled" : ""
                          }`}
                          onClick={() => {
                            if (state?.language !== "en") {
                              setManageSwitch(true);
                              resetForm({});
                              // setSelectedDiscountType("");
                            }
                          }}
                        >
                          Spanish
                        </div>
                        <div
                          className={
                            !manageSwitch
                              ? "switch-wrapper"
                              : "switch-wrapper switchside"
                          }
                        >
                          <div className="switch">
                            {!manageSwitch ? (
                              <div>English</div>
                            ) : (
                              <div>Spanish</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="ptext">Select Discount component*</p>
                    <div className="d-md-flex flexWrapdpc">
                      {getGroupProductFeatureContentSelector?.data?.data?.content?.componants?.map(
                        (item, index) => {
                          return (
                            <div className="form-check me-30 p-0" key={index}>
                              <Radio
                              className={
                                item?.isSelected ? "radio-disabled" : ""
                              }
                                checked={values.selectedDiscountType === item?.name}
                                // onChange={() => {
                                //   setSelectedDiscountType(item?.name); 
                                // }}
                                onChange={() =>
                                  setFieldValue(
                                    "selectedDiscountType",
                                    item?.name
                                  )
                                }
                                disabled={item?.isSelected}
                              >
                                {item?.name}
                              </Radio>
                            </div>
                          );
                        }
                      )}
                    </div>
                    {errors.selectedDiscountType &&
                      touched.selectedDiscountType && (
                        <div className="error text-danger">
                          {errors.selectedDiscountType}
                        </div>
                      )}
                    <hr />
                    <div className="text-end">
                      <button
                        className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
                        type="submit"
                        // onClick={() =>
                        //   navigate("/add-dpc-discount-group", {
                        //     state: {
                        //       selectedDiscountType: selectedDiscountType,
                        //       manageSwitch: manageSwitch,
                        //       state: state?.fromDpcExtraGroup,
                        //     },
                        //   })
                        // }
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDpcExtraGroup;
