import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import Loader from "../../components/Loder/loader";
import Faq from "./Faq";
import LanguageSwitch from "../../common/LanguageSwitch";
import { BreadCrum } from "../../common";
import { fileUploadAction } from "../../redux/action/fileUpload";

const Editfaq = () => {
  const navigate = useNavigate();
  const { state } = useLocation()
  const dispatch = useDispatch()
  const [hideEditBtn, setHideBtn] = useState(false);
  const [manageSwitch, setManageSwitch] = useState(false);
  const getProductFeatureContentFaq = useSelector((state) => state?.getProductFeatureContentMain)

  useEffect(() => {
    let payload = {
      productId: state?.productId || state?.item?.productId,
      refFeatureId: state?.refFeatureId || state?.item?.refFeatureId
    }
    dispatch(getProductFeatureContentHandler(payload))
  }, [state])

  useEffect(() => {
    if (getProductFeatureContentFaq?.data?.data?.content?.english?.questions?.length > 0) {
      setManageSwitch(false)
    } else if (getProductFeatureContentFaq?.data?.data?.content?.spanish?.questions?.length > 0) {
      setManageSwitch(true)
    } else if (getProductFeatureContentFaq?.data?.data?.content?.spanish?.questions?.length > 0 && getProductFeatureContentFaq?.data?.data?.content?.english?.questions?.length > 0) {
      setManageSwitch(false)
    }
  }, [getProductFeatureContentFaq])


  return (
    <>
      {getProductFeatureContentFaq?.isLoading === true && <Loader loaderTransform="loderTransForm" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>FAQs</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: BreadCrum.PRODUCTS, onClick: () => { navigate("/dashboard"); localStorage.removeItem("productId"); dispatch(fileUploadAction.fileUploadInfoReset()) } },
              { text: BreadCrum.MANAGE_PRODUCTS, onClick: () => navigate("/edit-product") },
              { text: BreadCrum.FAQs },
              { text: BreadCrum.EDIT, active: true },
            ]}
          />
          {hideEditBtn === false ? (
            <Form className="login-form editfaq">
              <div className="table-padding mb-30">
                <div className="headtitle d-block">
                  <div className="d-flex justify-content-between align-items-center d-4grid">
                    <div>
                      <div className="productname mb-10">Product Name</div>
                      <div className="producttitle ">{getProductFeatureContentFaq?.data?.data?.productName || state?.productData?.productName}</div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="">
                      <div className="productname mb-10">Product Code</div>
                      <div className="producttitle ">{getProductFeatureContentFaq?.data?.data?.productCode || state?.productData?.productCode}</div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="">
                      <div className="productname mb-10">Product Status</div>
                      <div className="producttitle activetext">{getProductFeatureContentFaq?.data?.data?.activeStatus?.charAt(0)?.toUpperCase() + getProductFeatureContentFaq?.data?.data?.activeStatus?.slice(1)?.toLowerCase() || state?.productData?.productStatus?.charAt(0)?.toUpperCase() + state?.productData?.productStatus?.slice(1)?.toLowerCase()}</div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="text-end">
                      <button className="primarybtn ms-auto me-5" onClick={() => setHideBtn(true)}>
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <LanguageSwitch manageSwitch={manageSwitch} setManageSwitch={setManageSwitch} />
              {manageSwitch === false && (
                <>
                  <div>
                    <Accordion defaultActiveKey="0">
                      {getProductFeatureContentFaq?.data?.data?.content?.english?.questions?.map((item, index) => {
                        return (
                          <>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>{item?.question}</Accordion.Header>
                              <Accordion.Body>
                                {item?.answer}
                              </Accordion.Body>
                            </Accordion.Item>
                          </>
                        )
                      })}
                    </Accordion>
                  </div>
                  {getProductFeatureContentFaq?.data?.data?.content?.english?.questions?.length === 0 && (
                    <div className="removehtml bg-transparent">
                      No FAQs found!
                    </div>
                  )}
                </>
              )}
              {manageSwitch === true && (
                <>
                  <div>
                    <Accordion defaultActiveKey="0">
                      {getProductFeatureContentFaq?.data?.data?.content?.spanish?.questions?.length > 0 && getProductFeatureContentFaq?.data?.data?.content?.spanish?.questions?.map((item, index) => {
                        return (
                          <>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>{item?.question}</Accordion.Header>
                              <Accordion.Body>
                                {item?.answer}
                              </Accordion.Body>
                            </Accordion.Item>
                          </>
                        )
                      })}
                    </Accordion>
                  </div>
                  {getProductFeatureContentFaq?.data?.data?.content?.spanish?.questions?.length === 0 && (
                    <div className="removehtml bg-transparent">
                      No FAQs found!
                    </div>
                  )}
                </>
              )}
            </Form>
          ) : (
            <>
              <div className="table-padding mb-30">
                <div className="headtitle d-block">
                  <div className="d-flex justify-content-between align-items-center d-4grid">
                    <div>
                      <div className="productname mb-10">Product Name </div>
                      <div className="producttitle ">{getProductFeatureContentFaq?.data?.data?.productName || state?.productData?.productName}</div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="">
                      <div className="productname mb-10">Product Code</div>
                      <div className="producttitle ">{getProductFeatureContentFaq?.data?.data?.productCode || state?.productData?.productCode}</div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="">
                      <div className="productname mb-10">Product Status</div>
                      <div className="producttitle activetext">{getProductFeatureContentFaq?.data?.data?.activeStatus?.charAt(0)?.toUpperCase() + getProductFeatureContentFaq?.data?.data?.activeStatus?.slice(1)?.toLowerCase() || state?.productData?.productStatus?.charAt(0)?.toUpperCase() + state?.productData?.productStatus?.slice(1)?.toLowerCase()}</div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="text-end">
                      <button className="primarybtn ms-auto me-5" onClick={() => setHideBtn(true)} disabled={hideEditBtn}>
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="editFaqSpacing">
                <Faq faqState={state} setHideBtn={setHideBtn} hideEditBtn={hideEditBtn} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Editfaq;
