import { createSlice } from "@reduxjs/toolkit";
import { getUserDetailsAPI } from "../../services/getUserDetails";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const getUserDetailsSlice = createSlice({
  name: "getUserDetails",
  initialState: data,
  reducers: {
    getUserDetailsInfo(state) {
      state.isLoading = true;
    },
    getUserDetailsInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    getUserDetailsInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    getUserDetailsInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const getUserDetailsHandler = (data) => async (dispatch) => {
  try {
    dispatch(getUserDetailsAction.getUserDetailsInfo());
    const response = await getUserDetailsAPI(data);
    dispatch(getUserDetailsAction.getUserDetailsInfoSuccess(response));
  } catch (e) {
    dispatch(getUserDetailsAction.getUserDetailsInfoFailure(e?.response?.data?.message));
  }
};
export default getUserDetailsSlice.reducer;
export const getUserDetailsAction = getUserDetailsSlice.actions;
