import { message } from 'antd';
import React from 'react'

const LanguageSwitch = ({ manageSwitch, setManageSwitch, fileUploadDataSelector }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const manageEnglishSwitch = () => {
        if (fileUploadDataSelector?.data?.data) {
            messageApi.open({
                type: 'warning',
                content: "Please Save File First!!",
            });
            setManageSwitch(true)
        } else {
            setManageSwitch(false)
        }
    }

    const manageSpanishSwitch = () => {
        if (fileUploadDataSelector?.data?.data) {
            messageApi.open({
                type: 'warning',
                content: "Please Save File First!!",
            });
            setManageSwitch(false)
        } else {
            setManageSwitch(true)
        }
    }

    return (
        <>
            <div className="switches-container mb-30">
                <div className="mempro" onClick={manageEnglishSwitch}>
                    English
                </div>
                <div className="mempro" onClick={manageSpanishSwitch}>
                    Spanish
                </div>
                <div
                    className={
                        !manageSwitch ? "switch-wrapper" : "switch-wrapper switchside"
                    }
                >
                    <div className="switch">
                        {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LanguageSwitch
