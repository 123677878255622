import { createSlice } from "@reduxjs/toolkit";
import { getAllStatesAPI } from "../../services/getAllStates";

const data = {
    isLoading: false,
    error: "",
    message: "",
    data: null,
};

const getAllStatesSlice = createSlice({
    name: "getAllStates",
    initialState: data,
    reducers: {
        getAllStatesInfo(state) {
            state.isLoading = true;
        },
        getAllStatesInfoSuccess(state, action) {
            state.isLoading = false;
            state.data = action.payload;
            state.message = "";
        },
        getAllStatesInfoFailure(state, action) {
            state.isLoading = false;
            state.message = action.payload;
            state.data = null;
        },
        getAllStatesInfoReset(state) {
            state.isLoading = false;
            state.message = "";
            state.data = null;
            state.error = "";
        },
    },
});

export const getAllStatesHandler = (data) => async (dispatch) => {
    try {
        dispatch(getAllStatesAction.getAllStatesInfo());
        const response = await getAllStatesAPI(data);
        dispatch(getAllStatesAction.getAllStatesInfoSuccess(response));
    } catch (e) {
        dispatch(getAllStatesAction.getAllStatesInfoFailure(e?.response?.data?.message));
    }
};
export default getAllStatesSlice.reducer;
export const getAllStatesAction = getAllStatesSlice.actions;
