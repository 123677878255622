import React, { useContext, useState } from "react";
import logo12 from "../../assets/HealthCare2u_Logo.svg";
import logoprimecare from "../../assets/primecare.svg";
import logout from "../../assets/logout.svg";
import logoutprime from "../../assets/logoutprime.svg";
import healthsideimg from "../../assets/healthsideimg.svg";
import sidemenu from "../../assets/sidemenu.svg";
import productselected from "../../assets/productselected.svg";
import healthproduct from "../../assets/product.svg";
import user from "../../assets/user.svg";
import groups from "../../assets/groups.svg";
import noti from "../../assets/noti.svg";
import reportActive from "../../assets/reports_selected.svg";
import reportInActive from "../../assets/reports_inactive.svg";
import userselcted from "../../assets/userselcted.svg";
import grouselected from "../../assets/grouselected.svg";
import notiselected from "../../assets/notiselected.svg";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSliceAction } from "../../redux/action/loginSlice";
import { addProductAction } from "../../redux/action/addProductSlice";
import { getProductFeaturesAction } from "../../redux/action/getproductFeaturesSlice";
import { getGroupProductFeatureContentAction } from "../../redux/action/getGroupProductFeatureContent";
import { getProductFeatureContentAction } from "../../redux/action/getProductFeatureContentSlice";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { ThemeContext } from "../../common/themeContext";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";

const Sidebar = ({ children }) => {
  const { toggleTheme, brandId } = useContext(ThemeContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleItemClick = (path) => {
    navigate(path);
  };
  const logoutFn = () => {
    dispatch(loginSliceAction.loginDetailsSliceReset());
    localStorage.removeItem("token");
    localStorage.removeItem("productId");
    navigate("/");
  };

  const handleModalYes = () => {
    toggleTheme();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleProductSideBar =
    location?.pathname === "/dashboard" ||
    location?.pathname === "/addproduct" ||
    location.pathname === "/edit-product";

  const handleActiveUserSideBar =
    location?.pathname === "/user" || location?.pathname === "/userTemp";

  const handleGroupSideBar =
    location?.pathname === "/groups" ||
    location?.pathname === "/manage-member" ||
    location?.pathname === "/edit-group-product";

  const handleNotificationSideBar =
    location?.pathname === "/notification" ||
    location?.pathname === "/create-notification";
  const handleReportSideBar = location?.pathname === "/reports";

  return (
    <>
      <div
        style={{ display: "flex", height: "100vh", overflow: "scroll initial" }}
      >
        <div className={`sidebar ${isSidebarOpen ? "" : "toggled"}`}>
          <div className="sidebar-header">
            <div className="head-div">
              <span className="icon-suffix" onClick={toggleSidebar}>
                <img src={healthsideimg} className=" logouthealth" alt="" />
                <img src={sidemenu} className="logoutprime" alt="" />
              </span>
              <span
                className="head-text"
                onClick={() => navigate("/dashboard")}
              >
                <Link className="text-decoration-none">
                  <img
                    src={logo12}
                    className="h-100 w-100 healthcareImg"
                    alt="Healthcare Logo"
                  />
                  <img
                    src={logoprimecare}
                    className="h-100 w-100 primecareImg"
                    alt="Primecare Logo"
                  />
                </Link>
              </span>
            </div>
          </div>
          <div className="sidebar-content">
            <ul className="list-unstyled">
              <li
                className={`sc-cfxfcM hQSxZz d-flex  ${
                  handleProductSideBar ? "active" : ""
                }`}
                onClick={() => {
                  handleItemClick("/dashboard");
                  dispatch(addProductAction.addProductSliceInfoReset());
                  dispatch(
                    getProductFeaturesAction.getProductFeaturesInfoReset()
                  );
                  dispatch(
                    getGroupProductFeatureContentAction.getGroupProductFeatureContentReset()
                  );
                  dispatch(
                    getProductFeatureContentAction.getProductFeatureContentInfoReset()
                  );
                  dispatch(fileUploadAction.fileUploadInfoReset());
                  localStorage.removeItem("productId");
                }}
              >
                <div className="hoverimges">
                  <img className="normalimg" src={healthproduct} alt="" />

                  <img className="selectimg" src={productselected} alt="" />
                </div>
                <span className="item-content">Products</span>
              </li>
              <li
                className={`sc-cfxfcM hQSxZz d-flex  ${
                  handleActiveUserSideBar ? "active" : ""
                }`}
                onClick={() => {
                  handleItemClick("/user");
                  dispatch(addProductAction.addProductSliceInfoReset());
                  dispatch(
                    getGroupProductFeatureContentAction.getGroupProductFeatureContentReset()
                  );
                  dispatch(
                    getProductFeaturesAction.getProductFeaturesInfoReset()
                  );
                  dispatch(
                    getProductFeatureContentAction.getProductFeatureContentInfoReset()
                  );
                  dispatch(fileUploadAction.fileUploadInfoReset());
                  localStorage.removeItem("productId");
                }}
              >
                <div className="hoverimges">
                  <img className="normalimg" src={user} alt="" />

                  <img className="selectimg" src={userselcted} alt="" />
                </div>
                <span className="item-content">Users</span>
              </li>
              <li
                className={`sc-cfxfcM hQSxZz d-flex  ${
                  handleGroupSideBar ? "active" : ""
                }`}
                onClick={() => {
                  handleItemClick("/groups");
                  dispatch(addProductAction.addProductSliceInfoReset());
                  dispatch(
                    getGroupProductFeatureContentAction.getGroupProductFeatureContentReset()
                  );
                  dispatch(
                    getProductFeaturesAction.getProductFeaturesInfoReset()
                  );
                  dispatch(
                    getProductFeatureContentAction.getProductFeatureContentInfoReset()
                  );
                  dispatch(fileUploadAction.fileUploadInfoReset());
                  localStorage.removeItem("productId");
                }}
              >
                <div className="hoverimges">
                  <img className="normalimg" src={groups} alt="" />

                  <img className="selectimg" src={grouselected} alt="" />
                </div>
                <span className="item-content">Groups</span>
              </li>
              <li
                className={`sc-cfxfcM hQSxZz d-flex  ${
                  handleNotificationSideBar ? "active" : ""
                }`}
                onClick={() => {
                  handleItemClick("/notification");
                  dispatch(addProductAction.addProductSliceInfoReset());
                  dispatch(
                    getGroupProductFeatureContentAction.getGroupProductFeatureContentReset()
                  );
                  dispatch(
                    getProductFeaturesAction.getProductFeaturesInfoReset()
                  );
                  dispatch(
                    getProductFeatureContentAction.getProductFeatureContentInfoReset()
                  );
                  dispatch(fileUploadAction.fileUploadInfoReset());
                  localStorage.removeItem("productId");
                }}
              >
                <div className="hoverimges">
                  <img className="normalimg" src={noti} alt="" />

                  <img className="selectimg" src={notiselected} alt="" />
                </div>
                <span className="item-content">Notifications</span>
              </li>
              {brandId === 2 && (
                <li
                  className={`sc-cfxfcM hQSxZz d-flex  ${
                    handleReportSideBar ? "active" : ""
                  }`}
                  onClick={() => {
                    handleItemClick("/reports");
                    dispatch(addProductAction.addProductSliceInfoReset());
                    dispatch(
                      getGroupProductFeatureContentAction.getGroupProductFeatureContentReset()
                    );
                    dispatch(
                      getProductFeaturesAction.getProductFeaturesInfoReset()
                    );
                    dispatch(
                      getProductFeatureContentAction.getProductFeatureContentInfoReset()
                    );
                    dispatch(fileUploadAction.fileUploadInfoReset());
                    localStorage.removeItem("productId");
                  }}
                >
                  <div className="hoverimges">
                    <img className="normalimg" src={reportInActive} alt="" />

                    <img className="selectimg" src={reportActive} alt="" />
                  </div>
                  <span className="item-content">Reports</span>
                </li>
              )}
            </ul>
          </div>
          <div className="sidebar-footer text-center">
            <button
              type="button"
              className=" btn-lg btn-toggle rounded-pill"
              data-toggle="button"
              aria-pressed="false"
              autoComplete="off"
              onClick={() => setIsModalOpen(true)}
            >
              <div
                className={`handle active ${isActive ? "active" : ""}`}
              ></div>
            </button>
            <hr />
            <div
              className="logout-link logout d-flex justify-content-center"
              onClick={logoutFn}
            >
              <img src={logout} className="logoutprime" alt="Logout" />
              <img src={logoutprime} className="logouthealth" alt="Logout" />
              <span>Logout</span>
            </div>
          </div>
        </div>
        {children}
      </div>
      {isModalOpen && (
        <BrandChangeModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleModalYes={handleModalYes}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default Sidebar;
