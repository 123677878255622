import { createSlice } from "@reduxjs/toolkit";
import { addProductFeaturesAPI } from "../../services/addProductFeatures";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const addProductFeaturesSlice = createSlice({
  name: "addProductFeatures",
  initialState: data,
  reducers: {
    addProductFeaturesInfo(state) {
      state.isLoading = true;
    },
    addProductFeaturesInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    addProductFeaturesInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    addProductFeaturesInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const addProductFeaturesHandler = (data) => async (dispatch) => {
  try {
    dispatch(addProductFeatureAction.addProductFeaturesInfo());
    const response = await addProductFeaturesAPI(data);
    dispatch(addProductFeatureAction.addProductFeaturesInfoSuccess(response));
  } catch (e) {
    dispatch(addProductFeatureAction.addProductFeaturesInfoFailure(e));
  }
};
export default addProductFeaturesSlice.reducer;
export const addProductFeatureAction = addProductFeaturesSlice.actions;
