import { createSlice } from '@reduxjs/toolkit';
import { deleteNotifications } from '../../services/deleteNotification';


const data = {
  isLoading: false,
  error: '',
  message: '',
  data: null,
};

const deleteNotificationSlice = createSlice({
  name: 'deleteNotification',
  initialState: data,
  reducers: {
    deleteNotificationSliceInfo(state) {
      state.isLoading = true;
    },
    deleteNotificationSliceInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = '';
    },
    deleteNotificationSliceInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    deleteNotificationSliceInfoReset(state) {
      state.isLoading = false;
      state.message = '';
      state.data = null;
      state.error = '';
    },
  },
});

export const deleteNotificationHandler = (data) => async (dispatch) => {
  try {
    dispatch(deleteNotificationAction.deleteNotificationSliceInfo());
    const response = await deleteNotifications(data);
    dispatch(
      deleteNotificationAction.deleteNotificationSliceInfoSuccess(response)
    );
  } catch (e) {
    dispatch(
      deleteNotificationAction.deleteNotificationSliceInfoFailure(
        e?.response?.data?.message
      )
    );
  }
};
export default deleteNotificationSlice.reducer;
export const deleteNotificationAction = deleteNotificationSlice.actions;
