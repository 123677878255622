import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loder/loader";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [brandId, setBrandId] = useState(null);
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme || "healthCare";
  });
  const navigate = useNavigate();
  const toggleTheme = () => {
    setIsLoading(true);
    navigate("/dashboard");
    setTheme((prevTheme) =>
      prevTheme === "healthCare" ? "primeCare" : "healthCare"
    );
  };

  //  Set Brand Id as per change brand Like healthCare and Primecare
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem("theme", theme);
      const refBrandId = theme === "healthCare" ? 1 : 2;
      localStorage.setItem("refBrandId", refBrandId);
      setBrandId(refBrandId);
      setIsLoading(false);
    }, 200); // debounce with 300ms

    return () => clearTimeout(timeoutId);
  }, [theme]);

  return (
    <>
      {isLoading && <Loader loaderTransform="loaderTransform" />}
      <ThemeContext.Provider value={{ theme, toggleTheme, brandId, isLoading }}>
        {children}
      </ThemeContext.Provider>
    </>
  );
};
