import { createSlice } from "@reduxjs/toolkit";
import { getAllGroupsAPI } from "../../services/getAllGroups";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const getAllGroupSlice = createSlice({
  name: "getAllGroups",
  initialState: data,
  reducers: {
    getAllGroupsInfo(state) {
      state.isLoading = true;
    },
    getAllGroupsInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    getAllGroupsInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    getAllGroupsInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const getAllGroupsHandler = (data) => async (dispatch) => {
  try {
    dispatch(getAllGroupsAction.getAllGroupsInfo());
    const response = await getAllGroupsAPI(data);
    dispatch(getAllGroupsAction.getAllGroupsInfoSuccess(response));
  } catch (e) {
    dispatch(getAllGroupsAction.getAllGroupsInfoFailure(e?.response?.data?.message));
  }
};
export default getAllGroupSlice.reducer;
export const getAllGroupsAction = getAllGroupSlice.actions;
