import { createSlice } from "@reduxjs/toolkit";
import { getProductFeatureContentAPI } from "../../services/getProductFeatureContent";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const getProductFeatureContentSlice = createSlice({
  name: "getProductFeatureContentMain",
  initialState: data,
  reducers: {
    getProductFeatureContentInfo(state) {
      state.isLoading = true;
    },
    getProductFeatureContentInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    getProductFeatureContentInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    getProductFeatureContentInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const getProductFeatureContentHandler = (data) => async (dispatch) => {
  try {
    dispatch(getProductFeatureContentAction.getProductFeatureContentInfo());
    const response = await getProductFeatureContentAPI(data);
    dispatch(getProductFeatureContentAction.getProductFeatureContentInfoSuccess(response));
  } catch (e) {
    dispatch(getProductFeatureContentAction.getProductFeatureContentInfoFailure(e?.response?.data?.message));
  }
};
export default getProductFeatureContentSlice.reducer;
export const getProductFeatureContentAction = getProductFeatureContentSlice.actions;
