import React, { useEffect, useState } from "react";
import addbtn from "../../assets/Add_Button.svg";
import deletebtn from "../../assets/Delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import Loader from "../../components/Loder/loader";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { addProductFeatureAction, addProductFeaturesHandler } from "../../redux/action/addProductFeaturesSlice";
import { BreadCrum, HttpStatusCode } from "../../common";
import { handleKeyPressSpace, handlePaste } from "../../common/CommonFunctions";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { validationSchemaFaq, validationSchemaSpanishFaq } from "../../common/Validations";
import { useCommonMessage } from "../../common/CommonMessage";

const Faq = ({ faqState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { state } = useLocation()
  const location = useLocation()
  const [manageSwitch, setManageSwitch] = useState(false);
  const [initialValueEnglish, setInitialValueEnglish] = useState([])
  const [initialValueSpanish, setInitialValueSpanish] = useState([])
  const messageApi = useCommonMessage();

  const getProductFeatureContentFaq = useSelector((state) => state?.getProductFeatureContentMain)
  const addProductFeaturesSelector = useSelector((state) => state?.addProductFeatures)

  useEffect(() => {
    let payload = {
      productId: state?.productId || faqState?.item?.productId,
      refFeatureId: state?.refFeatureId || faqState?.item?.refFeatureId
    }
    dispatch(getProductFeatureContentHandler(payload))
  }, [state])


  const handleFormSubmit = (values) => {
    const trimFields = (questions) => {
      return questions.map((q) => ({
        question: q.question.trim(),
        answer: q.answer.trim(),
      }));
    };
    if (manageSwitch === false) {
      let faqPayload = {
        productId: getProductFeatureContentFaq?.data?.data?.productId || faqState?.item?.productId,
        featureId: getProductFeatureContentFaq?.data?.data?.refFeatureId || faqState?.item?.refFeatureId,
        content: {
          faq_json: true,
          english: { questions: trimFields(values?.questions || initialValueEnglish) },
          spanish: {
            questions: getProductFeatureContentFaq?.data?.data?.content?.spanish?.questions
          }
        }
      };
      dispatch(addProductFeaturesHandler(faqPayload))
    } else if (manageSwitch === true) {
      let faqPayload = {
        productId: getProductFeatureContentFaq?.data?.data?.productId || faqState?.item?.productId,
        featureId: getProductFeatureContentFaq?.data?.data?.refFeatureId || faqState?.item?.refFeatureId,
        content: {
          faq_json: true,
          english: { questions: getProductFeatureContentFaq?.data?.data?.content?.english?.questions },
          spanish: { questions: trimFields(values?.questionSpanish || initialValueSpanish) }
        }
      };
      dispatch(addProductFeaturesHandler(faqPayload))
    }
  }

  // Set Initial value
  useEffect(() => {
    if (manageSwitch === false) {
      let data = getProductFeatureContentFaq?.data?.data?.content?.english?.questions?.map((item) => {
        return {
          question: item?.question || '',
          answer: item?.answer || ''
        }
      });
      setInitialValueEnglish(data);
    } else if (manageSwitch === true) {
      let data = getProductFeatureContentFaq?.data?.data?.content?.spanish?.questions?.map((item) => {
        return {
          question: item?.question || '',
          answer: item?.answer || ''
        }
      });
      setInitialValueSpanish(data);
    }
  }, [getProductFeatureContentFaq, manageSwitch]);

  useEffect(() => {
    if (addProductFeaturesSelector?.data?.statusCode === HttpStatusCode.OK) {
      messageApi.open({
        type: 'success',
        content: addProductFeaturesSelector?.data?.message,
      });
      let payload = {
        productId: state?.productId || faqState?.item?.productId,
        refFeatureId: state?.refFeatureId || faqState?.item?.refFeatureId
      }
      dispatch(getProductFeatureContentHandler(payload))
      dispatch(addProductFeatureAction.addProductFeaturesInfoReset())
      // Get product features content handler also when add data from faq then faqState data will get
      if (faqState?.item?.productId) {
        window.location.reload("/edit-faq")
      }
    }
  }, [addProductFeaturesSelector])

  const handleMainSwitchEnglish = (resetForm) => {
    setManageSwitch(false)
    resetForm();
  }

  const handleMainSwitchSpanish = (resetForm) => {
    setManageSwitch(true)
    resetForm()
  }

  return (
    <>
      {(getProductFeatureContentFaq?.isLoading === true || addProductFeaturesSelector?.isLoading === true) && <Loader loaderTransForm="loaderTransform" />}
      <div className="rightside">
        <div className="rightcontent">
          {location?.pathname?.split("/")?.[1] !== "edit-faq" && (
            <>
              <p>
                <strong>{state?.item?.featureName|| state?.fromGroupData?.featureName || state?.featureName}</strong>
              </p>

              <CustomBreadcrumb
                items={[
                  { text: BreadCrum.HOME },
                  { text: BreadCrum.PRODUCTS, onClick: () => { navigate("/dashboard"); localStorage.removeItem("productId"); dispatch(fileUploadAction.fileUploadInfoReset()) } },
                  { text: state?.manageProduct ? BreadCrum.MANAGE_PRODUCTS : BreadCrum.ADD_PRODUCT, onClick: () => navigate(state?.manageProduct ? "/edit-product" : "/addproduct") },
                  { text: BreadCrum.FAQs, active: true },
                ]}
              />
            </>
          )}
          <Formik
            enableReinitialize
            initialValues={{
              questions: initialValueEnglish?.length > 0 ? initialValueEnglish : [{ question: '', answer: '' }],
              questionSpanish: initialValueSpanish?.length > 0 ? initialValueSpanish : [{ question: '', answer: '' }]
            }}
            validationSchema={manageSwitch === false ? validationSchemaFaq : validationSchemaSpanishFaq}
            onSubmit={(values, formikBag) => {
              handleFormSubmit(values, formikBag);
            }}
          >
            {({ values, resetForm }) => (
              <Form>
                <div className="d-md-flex align-items-center  mb-30">
                  <p className="mb-0 heedprodct">Add FAQs</p>
                  <div className="switches-container ">
                    <div className="mempro" onClick={() => handleMainSwitchEnglish(resetForm)}>
                      English
                    </div>
                    <div className="mempro" onClick={() => handleMainSwitchSpanish(resetForm)}>
                      Spanish
                    </div>
                    <div
                      className={
                        !manageSwitch ? "switch-wrapper" : "switch-wrapper switchside"
                      }
                    >
                      <div className="switch">
                        {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <FieldArray name={manageSwitch === false ? "questions" : "questionSpanish"}>
                  {({ push, remove }) => (
                    <>
                      {(manageSwitch === false ? values?.questions : values?.questionSpanish)?.map((_, index) => (
                        <div key={index} className="table-padding hoversection mb-40 p-30">
                          <div className="mb-25 position-relative">
                            <label>Question*</label>
                            <Field
                              as="textarea"
                              name={`${manageSwitch === false ? `questions[${index}].question` : `questionSpanish[${index}].question`}`}
                              placeholder="Enter your question"
                              className="form-control"
                              maxLength="200"
                              onKeyPress={handleKeyPressSpace}
                              onPaste={handlePaste}
                            />
                            <ErrorMessage
                              name={`${manageSwitch === false ? `questions[${index}].question` : `questionSpanish[${index}].question`}`}
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="mb-25 position-relative">
                            <label>Answer*</label>
                            <Field
                              as="textarea"
                              placeholder="Type your Answer here"
                              className="form-control"
                              name={`${manageSwitch === false ? `questions[${index}].answer` : `questionSpanish[${index}].answer`}`}
                              maxLength="1000"
                              onKeyPress={handleKeyPressSpace}
                              onPaste={handlePaste}
                            />
                            <ErrorMessage
                              name={`${manageSwitch === false ? `questions[${index}].answer` : `questionSpanish[${index}].answer`}`}
                              component="div"
                              className="error text-danger"
                            />
                          </div>

                          <hr />
                          <div className="w-100 text-end">
                            {(manageSwitch === false ? index === values?.questions?.length - 1 : index === values?.questionSpanish?.length - 1) && (
                              <button
                                className="plusicon faqIcon"
                                type="button"
                                onClick={() => push({ question: '', answer: '' })}
                              >
                                <img src={addbtn} alt="Add" />
                              </button>
                            )}
                            {index !== 0 && (
                              <button
                                type="button"
                                onClick={() => remove(index)}
                                className="faqIcon ms-20 plusicon "
                              >
                                <img src={deletebtn} alt="Delete" />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>

                <div className="text-end">
                  <button
                    type="submit"
                    className="sbg border-0 fw-18 gbo continuebtn savebtn"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Faq;
