
export const createPayload = (existingClaims) => {
    const getProductId = localStorage.getItem('productId');
    const refFeatureId = localStorage.getItem('refFeatureId');

    // For Form Functionality and redirection functionality
    return {
        productId: Number(getProductId),
        featureId: Number(refFeatureId),
        content: {
            type: "claim",
            claim: existingClaims?.map((item) => {
                const commonData = {
                    claimName: item?.claimName,
                    claimStatus: item?.claimStatus,
                    claimType: item?.claimType,
                    language: item?.language,
                    id: item?.id,
                };

                if (item?.claimType === 'form') {
                    return {
                        ...commonData,
                        formData: item?.formData,
                        formDescription: item?.formDescription,
                        formTitle: item?.formTitle,
                        setAttemptedQuestionsCount: item?.setAttemptedQuestionsCount,
                        setQuestionsCount: item?.setQuestionsCount,
                        urlData: item?.urlData,
                        quizData: item?.quizData,
                    };
                } else if (item?.claimType === 'url') {
                    return {
                        ...commonData,
                        urlData: item?.urlData,
                        formData: item?.formData,
                        quizData: item?.quizData,
                    };
                } else if (item?.claimType === 'quiz') {
                    return {
                        ...commonData,
                        quizData: item?.quizData,
                        setAttemptedQuestionsCount: item?.setAttemptedQuestionsCount,
                        setQuestionsCount: item?.setQuestionsCount,
                        urlData: item?.urlData,
                    }
                }
                else {
                    return commonData;
                }
            })
        }
    };
};
