import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { handleKeyPressSpace } from '../../common/CommonFunctions'
import CustomBreadcrumb from '../../common/CustomBreadcrumb'
import { BreadCrum } from '../../common'
import { addProductFeatureAction, addProductFeaturesHandler } from '../../redux/action/addProductFeaturesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getProductFeaturesHandler } from '../../redux/action/getproductFeaturesSlice'
import { useCommonMessage } from '../../common/CommonMessage'
import { v4 as uuidv4 } from 'uuid';
import {validationSchemaURLEnglish,validationSchemaURLSpanish} from "../../common/Validations"
import Loader from '../Loder/loader'
import { createPayload } from './claimPayload'

const RedirectionUrl = () => {
    const { state } = useLocation()
    const [redirectionUrlEnglish, setRedirectionUrlEnglish] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const messageApi = useCommonMessage()
    const getProductFeaturesSelector = useSelector((state) => state?.getProductFeatures)
    const addProductFeaturesSelector = useSelector((state) => state?.addProductFeatures)

    const getProductId = localStorage.getItem("productId")
    const refFeatureId = localStorage.getItem("refFeatureId")

    useEffect(() => {
        const productFeatures = getProductFeaturesSelector?.data?.data?.productFeature
        if (productFeatures?.length > 0) {
            const claimFeatures = productFeatures?.filter(feature => feature?.content?.type === "claim");
            if (claimFeatures?.length > 0) {
                for (let index = 0; index < claimFeatures?.length; index++) {
                    const element = claimFeatures[index];
                    setRedirectionUrlEnglish(element)
                }
            }
        }
    }, [getProductFeaturesSelector])

    const getProductFeatureFunction = () => {
        if (Number(refFeatureId)) {
            let payload = {
                productId: Number(getProductId),
                refFeatureId: Number(refFeatureId)
            }
            dispatch(getProductFeaturesHandler(payload))
        }
    }

    useEffect(() => {
        getProductFeatureFunction()
    }, [state])


    const handleFormSubmit = (values) => {
        const productId = Number(getProductId);
        const featureId = Number(refFeatureId);
        const existingClaims = redirectionUrlEnglish?.content?.claim?.map((item) => {
            return {
                id: item?.id,
                language: item?.language,
                urlData: item?.urlData,
                claimName: item?.claimName,
                claimType: item?.claimType,
                claimStatus: item?.claimStatus,
                formData: item?.formData,
                formDescription: item?.formDescription,
                formTitle: item?.formTitle,
                quizData: item?.quizData,
                quizName: item?.quizName,
                quizDescription: item?.quizDescription,
            }
        }) || [];

        // New claim added
        const newClaim = {
            id: uuidv4(),
            language: state?.handleSwitch === false ? "en" : "es",
            urlData: state?.handleSwitch === false ? values?.redirection_URLEnglish : values?.redirection_URLSpanish,
            claimName: state?.values?.claimName || state?.values?.claimNameSpanish || state?.claimData?.claimName || state?.claimData?.claimNameSpanish,
            claimType: state?.values?.claimType || state?.values?.claimTypeSpanish || state?.claimData?.claimType || state?.claimData?.claimTypeSpanish,
            claimStatus: "ACTIVE",
            formData: [],
            quizData: [],
            formDescription: "",
            formTitle: "",
            quizName: "",
            quizDescription: "",
        };

        const matchSeqId = redirectionUrlEnglish?.content?.claim?.findIndex((item) => item?.id === state?.claimData?.id)

        if (matchSeqId > -1) {
            existingClaims[matchSeqId] = newClaim;
        } else {
            existingClaims.push(newClaim);
        }

        const payload = {
            productId,
            featureId,
            content: {
                type: "claim",
                claim: existingClaims
            }
        };
        const payload11 = createPayload(existingClaims);
        dispatch(addProductFeaturesHandler(payload));
    };

    // When Product Add then this useEffect Will call
    useEffect(() => {
        if (addProductFeaturesSelector?.data?.statusCode === 200) {
            messageApi.open({
                type: "success",
                content: addProductFeaturesSelector?.data?.message
            })
            dispatch(addProductFeatureAction.addProductFeaturesInfoReset())
            setTimeout(() => {
                navigate("/claim")
            }, 1000);
            getProductFeatureFunction()
        } else if (addProductFeaturesSelector?.message) {
            messageApi.open({
                type: "error",
                content: addProductFeaturesSelector?.message
            })
            dispatch(addProductFeatureAction.addProductFeaturesInfoReset())
        }
    }, [addProductFeaturesSelector])

    return (
        <>
            {(getProductFeaturesSelector?.isLoading === true || addProductFeaturesSelector?.isLoading === true) && <Loader loaderTransForm="loaderTransForm" />}
            <div className="rightside">
                <div className="rightcontent">
                    <p>
                        <strong>Products</strong>
                    </p>
                    <CustomBreadcrumb
                        items={[
                            { text: BreadCrum.HOME },
                            { text: BreadCrum.PRODUCTS, onClick: () => navigate("/dashboard") },
                            { text: BreadCrum.MANAGE_PRODUCTS, onClick: () => navigate("/edit-product") },
                            { text: BreadCrum.CLAIM_LIST, onClick: () => navigate("/claim") },
                            { text: BreadCrum.ADD_CLAIM },
                            { text: BreadCrum.REDIRECTION_URL, active: true },
                        ]}
                    />
                    <div className="redirectionlink d-n one">
                        <div className="sectionpadding d-block mb-30 hoversection">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0 heedprodct">Enter Redirection URL - {state?.handleSwitch === false ? "For English" : "For Spanish"}</p>
                            </div>
                            <hr />
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    redirection_URLEnglish: state?.claimData?.urlData,
                                    redirection_URLSpanish: state?.claimData?.urlData
                                }}
                                validationSchema={state?.handleSwitch === false ? validationSchemaURLEnglish : validationSchemaURLSpanish}
                                onSubmit={(values, formikBag) => {
                                    handleFormSubmit(values, formikBag);
                                }}
                            >
                                {({ }) => (
                                    <Form className="login-form">
                                        <div className='mb-30'>
                                            <Field
                                                type="text"
                                                name={state?.handleSwitch === false ? "redirection_URLEnglish" : "redirection_URLSpanish"}
                                                placeholder="Enter your redirection url"
                                                className="form-control w-md-50"
                                                onKeyPress={handleKeyPressSpace}
                                            />
                                            <ErrorMessage
                                                name={state?.handleSwitch === false ? "redirection_URLEnglish" : "redirection_URLSpanish"}
                                                component="div"
                                                className="error text-danger"
                                            />
                                        </div>
                                        <div className="text-end">
                                            <button className="sbg border-0 py3 fw-18 gbo continuebtn savebtn" type="submit">
                                                Save
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RedirectionUrl
