import { createSlice } from "@reduxjs/toolkit";
import { getGroupsMembersAPI } from "../../services/getGroupsMember";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const getGroupsMembersSlice = createSlice({
  name: "getAllGroupsMemberList",
  initialState: data,
  reducers: {
    getGroupsMembersInfo(state) {
      state.isLoading = true;
    },
    getGroupsMembersInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    getGroupsMembersInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    getGroupsMembersInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const getGroupsMembersHandler = (data) => async (dispatch) => {
  try {
    dispatch(getGroupsMembersAction.getGroupsMembersInfo());
    const response = await getGroupsMembersAPI(data);
    dispatch(getGroupsMembersAction.getGroupsMembersInfoSuccess(response));
  } catch (e) {
    dispatch(getGroupsMembersAction.getGroupsMembersInfoFailure(e?.response?.data?.message));
  }
};
export default getGroupsMembersSlice.reducer;
export const getGroupsMembersAction = getGroupsMembersSlice.actions;
