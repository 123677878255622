import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../common/CommonTable";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import Loader from "../../components/Loder/loader";
import { DPCExtraListColumn } from "./DPCExtraListColumn";

const DPCExtra = () => {
  const [handleDpcExtraList, setHandleDpcExtraList] = useState([]);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const getProductFeatureContent = useSelector(
    (state) => state?.getProductFeatureContentMain
  );

  const navigate = useNavigate();
  const dashBoardColumnsData = DPCExtraListColumn(navigate);

  const addDpcExtra = () => {
    navigate("/add-dpc", { state: { fromDpcExtra: state } });
  };

  const getProductId = localStorage.getItem("productId");
  const refFeatureId = localStorage.getItem("refFeatureId");

  const getProductFeatureFunction = () => {
    if (Number(getProductId) && Number(refFeatureId)) {
      let payload = {
        productId: Number(getProductId) || state?.state?.productData?.productId,
        refFeatureId:
          state?.state?.refFeatureId ||
          state?.refFeatureId ||
          Number(refFeatureId),
      };
      dispatch(getProductFeatureContentHandler(payload));
    }
  };

  useEffect(() => {
    getProductFeatureFunction();
  }, []);

  useEffect(() => {
    const dpcExtraList =
      getProductFeatureContent?.data?.data?.content?.componants
        ?.filter((item) => item?.isSelected)
        ?.map((filteredItem) => {
          return {
            ...filteredItem,
            discountComponents: filteredItem?.name,
            status: filteredItem?.status,
          };
        })
    setHandleDpcExtraList(dpcExtraList);
  }, [getProductFeatureContent]);

  return (
    <>
      {getProductFeatureContent?.isLoading === true && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>

          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.PRODUCTS,
                onClick: () => {
                  navigate("/dashboard");
                  localStorage.removeItem("productId");
                },
              },
              {
                text: BreadCrum.MANAGE_PRODUCTS,
                onClick: () => navigate("/edit-product"),
              },
              { text: BreadCrum.DPC_EXTRA_LIST, active: true },
            ]}
          />
          <div className="table-padding">
            <div className="headtitle">
              <p className="mb-0 heedprodct">DPCextra</p>
              <button className="primarybtn" onClick={addDpcExtra}>
                <div className="plus"></div>
                Add
              </button>
            </div>
            <hr className="m-0" />
            <div className="table-responsive">
              <CommonTable
                body={handleDpcExtraList}
                columns={dashBoardColumnsData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DPCExtra;
