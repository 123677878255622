import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./primecare.css";
import "./responsive.css";
import { ThemeProvider } from "./common/themeContext";
import { Provider } from "react-redux";
import store from "./redux/store";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <div className="mobileFont">
              <div className="max300">
              This website is not supported on Mobile web browsers.
              </div>
            </div>
            <div className="windowScreen">
              <AppRoutes />
            </div>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};
export default App;
