import React, { useContext, useEffect, useState } from "react";
import { Switch } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeaturesHandler } from "../../redux/action/getproductFeaturesSlice";
import Loader from "../../components/Loder/loader";
import {
  handleKeyPress,
  handleKeyPressSpace,
  handlePaste,
} from "../../common/CommonFunctions";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { ThemeContext } from "../../common/themeContext";
import {
  updateProductAction,
  updateProductHandler,
} from "../../redux/action/updateProduct-Slice";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";
import { BreadCrum } from "../../common";
import { useCommonMessage } from "../../common/CommonMessage";
import { validationSchemaProducts } from "../../common/Validations";

const Editproduct = () => {
  const getAllFeaturesSelector = useSelector(
    (state) => state?.getProductFeatures
  );
  const updateProductFeatures = useSelector(
    (state) => state?.updateProductSlice
  );
  const [switchInfos, setSwitchInfos] = useState({});
  const [activeStatus, setActiveStatus] = useState(false);
  const [productData, setShowProductData] = useState();
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const { brandId } = useContext(ThemeContext);
  const messageApi = useCommonMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const product_id = localStorage.getItem("productId");

  useEffect(() => {
    if (state?.productId || product_id) {
      let payload = {
        productId: state?.productId || product_id,
      };
      dispatch(getProductFeaturesHandler(payload));
      setShowProductData(state);
    }
  }, [state, product_id]);

  useEffect(() => {
    const newSwitchInfos = {};
    getAllFeaturesSelector?.data?.data?.productFeature?.forEach((element) => {
      if (element.status === "ACTIVE") {
        newSwitchInfos[element.id] = true;
      } else {
        newSwitchInfos[element.id] = false;
      }
    });
    setSwitchInfos(newSwitchInfos);
  }, [getAllFeaturesSelector]);

  // Switch Handler
  useEffect(() => {
    if (getAllFeaturesSelector?.data?.data?.activeStatus === "ACTIVE") {
      setActiveStatus(true);
    } else {
      setActiveStatus(false);
    }
  }, [getAllFeaturesSelector]);

  const handleSwitchChange = () => {
    setAddProductFeatureModal(true);
  };

  const handleCancel = () => {
    setAddProductFeatureModal(false);
  };

  const handleModalYes = () => {
    setAddProductFeatureModal(false);
    setActiveStatus((prevActiveStatus) => !prevActiveStatus);
  };

  const showSwitchModal = (item) => {
    setSwitchInfos((prevState) => ({
      ...prevState,
      [item?.id]: !prevState[item?.id],
    }));
    if (item?.productId && brandId) {
      let payload = {
        productId: item?.productId,
        refBrandId: brandId,
        productFeature: [
          {
            refFeatureId: item?.refFeatureId,
            GroupLevelStatusEdit: true,
            GroupLevelContentEdit: true,
            status:
              item?.status === "ACTIVE"
                ? "INACTIVE"
                : item?.status === "INACTIVE"
                ? "ACTIVE"
                : "",
          },
        ],
      };
      dispatch(updateProductHandler(payload));
    }
  };

  const getNavigateUrl = (item) => {
    switch (item?.refFeatureId) {
      case 1:
        navigate("/disclaimers", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 2:
        navigate("/access-membership", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 3:
        navigate("/membership-benefits", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 4:
        navigate("/plan-information", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 5:
        navigate("/summary-plan-description", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 6:
        navigate("/schedule-care", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 7:
        navigate("/insurance-certification", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 8:
        navigate("/digitized-id-card", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 9:
        navigate("/claim", {
          state: { state: item, productData: productData, manageProduct: true },
        });
        break;
      case 10:
        navigate("/edit-faq", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 11:
        navigate("/benefits-history", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      case 12:
        navigate("/dpc-extra", {
          state: { item: item, productData: productData, manageProduct: true },
        });
        break;
      default:
        navigate("/disclaimers");
        break;
    }
  };

  // Update product details
  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    let payload = {
      name: values?.product_name?.trim(),
      code: values?.product_code?.trim(),
      refBrandId: brandId,
      activeStatus: activeStatus === true ? "ACTIVE" : "INACTIVE",
      productId: state?.productId || product_id,
    };
    dispatch(updateProductHandler(payload));
  };

  useEffect(() => {
    if (updateProductFeatures?.data?.error === false) {
      messageApi.open({
        type: "success",
        content: updateProductFeatures?.data?.message,
      });
      let payload = {
        productId: product_id,
      };
      dispatch(getProductFeaturesHandler(payload));
      dispatch(updateProductAction.updateProductInfoReset());
    } else if (updateProductFeatures?.message) {
      messageApi.open({
        type: "error",
        content: updateProductFeatures?.message,
      });
      dispatch(updateProductAction.updateProductInfoReset());
    }
  }, [updateProductFeatures]);

  return (
    <>
      {(getAllFeaturesSelector?.isLoading === true ||
        updateProductFeatures?.isLoading === true) && (
        <Loader loaderTansform="loaderTansform" />
      )}
      <div className="rightside ">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.PRODUCTS,
                onClick: () => {
                  navigate("/dashboard");
                  localStorage.removeItem("productId");
                },
              },
              { text: BreadCrum.MANAGE_PRODUCTS, active: true },
            ]}
          />
          <div className="login-form mx750">
            <div className="table-padding mb-30 ">
              <div className="headtitle d-block">
                <p className=" heedprodct mb-3">Manage Product</p>
                <hr />
                <Formik
                  enableReinitialize
                  initialValues={{
                    product_name:
                      getAllFeaturesSelector?.data?.data?.name || "",
                    product_code:
                      getAllFeaturesSelector?.data?.data?.code || "",
                  }}
                  validationSchema={validationSchemaProducts}
                  onSubmit={(values, formikBag) => {
                    handleFormSubmit(values, formikBag);
                  }}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Form className="login-form">
                      <div className="d-xl-flex align-items-center ">
                        <div className="d-flex w-100 me-50 ">
                          <div
                            className="w-md-50 pe-md-2 mb-lg-0 mb-3 position-relative overlayinput"
                            controlId="formBasicPassword"
                          >
                            <label className="mb-2">Product Name*</label>
                            <Field
                              type="text"
                              name="product_name"
                              placeholder="Enter your product name"
                              className="form-control"
                              onKeyPress={handleKeyPressSpace}
                              onPaste={handlePaste}
                              maxLength={30}
                            />
                            <ErrorMessage
                              name="product_name"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div
                            className=" w-md-50 ps-md-2 position-relative overlayinput"
                            controlId="formBasicPassword"
                          >
                            <label className="mb-2">Product Code*</label>
                            <Field
                              type="text"
                              name="product_code"
                              placeholder="Enter your product code"
                              className="form-control"
                              onKeyPress={handleKeyPress}
                              onPaste={handlePaste}
                              maxLength={50}
                            />
                            <ErrorMessage
                              name="product_code"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div className="d-flex justifying">
                          <div className="mx-50 text-center">
                            <div className="productname mb-10 text-nowrap">
                              Product Status
                            </div>
                            <Switch
                              className="switches"
                              checked={activeStatus}
                              onChange={handleSwitchChange}
                            />
                          </div>
                          <div className="text-center ms-50">
                            <button className="editbuttom" type="submit">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="grid2 mb-30">
              {getAllFeaturesSelector?.data?.data?.activeStatus === "ACTIVE" &&
                getAllFeaturesSelector?.data?.data?.productFeature?.map(
                  (item) => {
                    return (
                      <div className="optionrow">
                        <div className="option">
                          <div className="optionflex">
                            <div className="optiontext">
                              {item?.featureName}
                            </div>
                            <div className="d-flex align-items-center ">
                              <Switch
                                checked={switchInfos[item.id]}
                                className="me-3 switches"
                                onChange={() => showSwitchModal(item)}
                              />
                              <button
                                className="manage"
                                onClick={() => {
                                  getNavigateUrl(item);
                                  localStorage.setItem(
                                    "refFeatureId",
                                    item?.refFeatureId
                                  );
                                  localStorage.setItem(
                                    "featureName",
                                    item?.featureName
                                  );
                                }}
                                disabled={!switchInfos[item.id]}
                              >
                                Manage
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
          <div className="text-end">
            <button
              type="submit"
              onClick={() => {
                navigate("/dashboard");
                localStorage.removeItem("productId");
              }}
              className="  border-0 py3 fw-18 gbo ms-auto bgtransparent  addcreatebtn"
            >
              <span>Go back to listing</span>
            </button>
          </div>
        </div>
      </div>
      {addProductFeatureModal && (
        <BrandChangeModal
          addProductFeatureModal={addProductFeatureModal}
          handleCancel={handleCancel}
          handleModalYes={handleModalYes}
          getAllFeaturesSelector={getAllFeaturesSelector}
          activeStatus={activeStatus}
        />
      )}
    </>
  );
};

export default Editproduct;
