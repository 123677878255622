import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { TimePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, Space } from "antd";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { pathName } from "../../utils/commonUtils";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import deleteSimple from "../../assets/deleteSimple.svg";
import editSimple from "../../assets/Edit.svg";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";
import * as moment from "moment";
import dayjs from "dayjs";
import {
  updateNotificationsAction,
  updateNotificationsHandler,
} from "../../redux/action/updateNotificationsSlice";
import { getAllUserHandler } from "../../redux/action/getAllUserListSlice";
import { getAllGroupsHandler } from "../../redux/action/getAllGroups";
import { getProductHandler } from "../../redux/action/getAllProductSlice";
import { useCommonMessage } from "../../common/CommonMessage";
import {
  deleteNotificationAction,
  deleteNotificationHandler,
} from "../../redux/action/deleteNotification";
import Loader from "../../components/Loder/loader";
import * as Yup from "yup";
import { ThemeContext } from "../../common/themeContext";
import {
  createNotificationSchema,
  emailSchema,
  pushDescriptionSchema,
  smsSchema,
} from "../../common/Validations";

const UpdateNotification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const messageApi = useCommonMessage();
  const storedTheme = localStorage.getItem("theme");

  const { brandId } = useContext(ThemeContext);

  const [notificationType, setNotificationType] = useState(
    location?.state?.notificationConfigJson &&
      Object?.keys(location?.state?.notificationConfigJson)?.find(
        (item) => location?.state?.notificationConfigJson[item]
      )
  );
  const [getProduct, setGetProduct] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [deleteNotification, setDeleteNotification] = useState("");
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [inputDate] = useState(location?.state?.scheduledDate);

  const {
    sms,
    pushNotificationTitle,
    pushNotificationDescription,
    emailSubject,
    emailBody,
    id,
  } = location?.state;

  const smsData = Yup.object().shape({
    ...createNotificationSchema,
    ...smsSchema,
  });
  const emailData = Yup.object().shape({
    ...createNotificationSchema,
    ...emailSchema,
  });
  const pushDescriptionData = Yup.object().shape({
    ...createNotificationSchema,
    ...pushDescriptionSchema,
  });

  const getUserList = useSelector((state) => state?.getAllUserList);
  const getAllGroupsList = useSelector((state) => state?.getAllGroups);
  const getAllProductList = useSelector((state) => state?.getAllProductList);

  const updateNotification = useSelector(
    (state) => state?.updateNotificationsSlice
  );
  const deleteNotifications = useSelector(
    (state) => state?.deleteNotificationSlice
  );

  useEffect(() => {
    if (inputDate) {
      const dt = moment(inputDate);
      const formattedDate = dt.format("YYYY-MM-DD");
      const formattedTime = dt.format("HH:mm");
      formik.setValues({
        ...formik.values,
        date: formattedDate,
        time: formattedTime,
      });
    }

    if (
      sms ||
      pushNotificationTitle ||
      pushNotificationDescription ||
      emailSubject ||
      emailBody
    ) {
      formik.setFieldValue("description", sms);
      formik.setFieldValue("title", pushNotificationTitle);
      formik.setFieldValue("pushDescription", pushNotificationDescription);
      formik.setFieldValue("subject", emailSubject);
      formik.setFieldValue("body", emailBody);
    }
  }, [
    inputDate,
    location?.state,
    sms,
    pushNotificationTitle,
    pushNotificationDescription,
    emailSubject,
    emailBody,
  ]);

  const userDetails = userData.find(
    (id) =>
      id?.userId ===
      (location?.state?.notificationReceiver?.userId?.[0] ||
        location?.state?.notificationReceiver?.userId)
  );
  const groupDetails = groupsData.find(
    (id) =>
      id?.groupCode ===
      (location?.state?.notificationReceiver?.groupId?.[0] ||
        location?.state?.notificationReceiver?.groupId)
  );
  const productDetails = getProduct.find(
    (id) =>
      id?.productId ===
      (location?.state?.notificationReceiver?.productId?.[0] ||
        location?.state?.notificationReceiver?.productId)
  );

  useEffect(() => {
    handleMemberName();
    handleGroup();
    handleProduct();
  }, []);

  useEffect(() => {
    // Handle update notification
    if (updateNotification?.data?.data) {
      messageApi.open({
        type: "success",
        content: updateNotification?.data?.message,
      });

      setTimeout(() => {
        navigate("/notification");
      }, 1000);

      dispatch(updateNotificationsAction.updateNotificationsInfoReset());
    }

    // Handle delete notification
    if (deleteNotifications?.data?.data) {
      messageApi.open({
        type: "success",
        content: deleteNotifications?.data?.data,
      });

      setTimeout(() => {
        navigate("/notification");
      }, 1000);

      dispatch(deleteNotificationAction.deleteNotificationSliceInfoReset());
    }
  }, [updateNotification, deleteNotifications]);

  useEffect(() => {
    if (getAllProductList?.data?.data) {
      const productData = getAllProductList?.data?.data?.map((item) => ({
        productId: item?.id,
        productName: item?.name,
        code: item?.code,
      }));
      setGetProduct(productData);
    }

    if (getAllGroupsList?.data?.data) {
      const data = getAllGroupsList?.data?.data?.map((item) => {
        return {
          groupCode: item?.id,
          groupName: item.name,
          caspioEmployerGroupCode: item?.group_Code,
        };
      });
      setGroupsData(data);
    }

    if (getUserList?.data?.data) {
      const getUser = getUserList?.data?.data.map((item) => {
        return {
          firstName: item?.firstName,
          lastName: item?.lastName,
          userId: item?.id,
          caspioUserId: item?.caspioUserId,
        };
      });
      setUserData(getUser);
    }
  }, [getAllProductList, getAllGroupsList, getAllGroupsList, getUserList]);

  const handleProduct = () => {
    const payload = {
      offSet: 1,
      limit: 100,
      refBrandId: storedTheme === "healthCare" ? 1 : 2,
    };
    dispatch(getProductHandler(payload));
  };

  const handleGroup = () => {
    const payload = {
      offSet: 1,
      limit: 100,
      brandId: storedTheme === "healthCare" ? 1 : 2,
    };
    dispatch(getAllGroupsHandler(payload));
  };

  const handleMemberName = () => {
    const payload = {
      offSet: 1,
      limit: 100,
      refBrandId: storedTheme === "healthCare" ? 1 : 2,
    };
    dispatch(getAllUserHandler(payload));
  };

  const handleCancel = () => {
    setAddProductFeatureModal(false);
  };

  const handleModalYes = () => {
    const payload = { id: id };
    setAddProductFeatureModal(false);
    setDeleteNotification("DeleteFile");
    dispatch(deleteNotificationHandler(payload));
  };

  const deleteNotificationHandlers = () => {
    setAddProductFeatureModal(true);
    setDeleteNotification("DeleteFile");
  };

  const formik = useFormik({
    initialValues: {
      productName: "",
      groupName: "",
      memberName: "",
      date: null,
      time: null,
      notificationType: notificationType,
      description: "",
      subject: "",
      body: "",
      title: "",
      pushDescription: "",
    },

    validationSchema:
      notificationType === "sms"
        ? smsData
        : notificationType === "email"
        ? emailData
        : notificationType === "pushNotification"
        ? pushDescriptionData
        : undefined,

    onSubmit: (value) => {
      const {
        date,
        time,
        notificationType,
        description,
        subject,
        body,
        title,
        pushDescription,
      } = value;
      const payload = {
        notificationReceiver: {
          userIds: userDetails
            ? userData
                .filter(
                  (user) =>
                    `${user?.firstName} ${user?.lastName}` ===
                    `${userDetails?.firstName} ${userDetails?.lastName}`
                )
                .map((user) => user?.userId)
            : undefined,
          productId: productDetails
            ? getProduct
                .filter(
                  (product) =>
                    product?.productName === productDetails?.productName
                )
                .map((product) => product?.productId)
            : undefined,
          groupId: groupDetails
            ? groupsData
                .filter((group) => group?.groupName === groupDetails?.groupName)
                .map((group) => group?.groupCode)
            : undefined,
        },
        id: id,
        eventType: brandId === 1 ? "ADMIN_TYPE" : "ADMIN_TYPE_PC",
        notificationConfigJson: {
          sms: notificationType === "sms",
          email: notificationType === "email",
          pushNotification: notificationType === "pushNotification",
        },
        scheduledDate: new Date(`${date} ${time}`).toISOString(),
        brandId: storedTheme === "healthCare" ? 1 : 2,
      };

      if (payload?.notificationConfigJson?.sms) {
        payload["sms"] = description;
      } else if (payload?.notificationConfigJson?.email) {
        payload["emailSubject"] = subject;
        payload["emailBody"] = body;
      } else if (payload?.notificationConfigJson?.pushNotification) {
        payload["pushNotificationTitle"] = title;
        payload["pushNotificationDescription"] = pushDescription;
      }
      dispatch(updateNotificationsHandler(payload));
    },
  });

  return (
    <div className="rightside">
      {(updateNotification?.isLoading === true ||
        deleteNotifications?.isLoading === true) && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="rightcontent">
        <p>
          <strong>Notifications</strong>
        </p>
        <CustomBreadcrumb
          items={[
            { text: "Home" },
            { text: "Notifications", onClick: () => navigate("/notification") },
            { text: location?.state?.deliveryStatus },
            { text: pathName(location), active: true },
          ]}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className="sectionpadding mb-30">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-4">
                <p className="mb-0 heedprodct">
                  Notification - {location?.state?.language}
                </p>
                <div
                  className={
                    location?.state?.deliveryStatus === "Scheduled"
                      ? "schedule"
                      : "sent"
                  }
                >
                  {location?.state?.deliveryStatus}
                </div>
              </div>
              <div className="d-flex gap-3">
                {location?.state?.deliveryStatus === "Scheduled" &&
                !editMode ? (
                  <img
                    src={editSimple}
                    alt=""
                    className="action-icon"
                    onClick={() => setEditMode(true)}
                  />
                ) : (
                  ""
                )}
                {location?.state?.deliveryStatus === "Scheduled" && (
                  <img
                    src={deleteSimple}
                    alt=""
                    className="action-icon"
                    onClick={() => deleteNotificationHandlers()}
                  />
                )}
              </div>
            </div>
            <hr />
            <div style={{ opacity: !editMode ? "0.5" : "1" }}>
              <div className="d-xxl-flex align-items-center mb-30">
                {location?.state?.notificationReceiver?.productId && (
                  <div className="pe-xxl-2 me-xxl-2 selecttype selecttype login-form">
                    <p className="labelp">Product Name</p>
                    <Form.Control
                      className="selectdisplayed"
                      type="text"
                      placeholder="Product Name"
                      value={
                        productDetails?.productName +
                        " " +
                        `(${productDetails?.code})`
                      }
                      disabled={Boolean(
                        location?.state?.notificationReceiver?.productId
                      )}
                    />
                  </div>
                )}

                {location?.state?.notificationReceiver?.groupId && (
                  <div className="pe-xxl-2 me-xxl-2 selecttype selecttype login-form">
                    <p className="labelp">Group Name</p>
                    <Form.Control
                      className="selectdisplayed"
                      type="text"
                      placeholder="Group Name"
                      value={
                        groupDetails?.groupName +
                        " " +
                        `(${groupDetails?.caspioEmployerGroupCode})`
                      }
                      disabled={Boolean(
                        location?.state?.notificationReceiver?.groupId
                      )}
                    />
                  </div>
                )}
                {(location?.state?.notificationReceiver?.userId ||
                  userDetails) && (
                  <div className="pe-xxl-2 me-xxl-2 selecttype selecttype login-form">
                    <p className="labelp">Member Name</p>
                    <Form.Control
                      className="selectdisplayed"
                      type="text"
                      placeholder="Member Name"
                      value={`${userDetails?.firstName} ${userDetails?.lastName}  (${userDetails?.caspioUserId})`}
                      disabled={Boolean(
                        location?.state?.notificationReceiver?.userId
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="d-xxl-flex align-items-center mb-30">
                <div className="pe-xxl-2 me-xxl-2  mb-xxl-0 mb-3 selecttype">
                  <p className="labelp">Select Date</p>
                  <Space direction="vertical" className="noicon">
                    <DatePicker
                      name="date"
                      placeholder="YYYY-MM-DD"
                      value={dayjs(formik?.values?.date, "YYYY-MM-DD")}
                      onChange={(date, dateString) => {
                        formik.setFieldValue("date", dateString);
                      }}
                      disabled={!editMode}
                      disabledDate={(current) => {
                        return current && current < moment().startOf("day");
                      }}
                      allowClear={false}
                    />
                  </Space>
                </div>
                <div className="pe-xxl-2 me-xxl-2 mb-xxl-0 mb-3 selecttype">
                  <p className="labelp">Select time</p>
                  <Space direction="vertical" className="noicon">
                    <TimePicker
                      placeholder="HH : MM"
                      format="HH:mm"
                      value={
                        formik.values.time
                          ? dayjs(formik?.values?.time, "HH:mm")
                          : null
                      }
                      onChange={(time, timeString) => {
                        formik.setFieldValue("time", timeString);
                      }}
                      disabled={!editMode}
                      allowClear={false}
                    />
                  </Space>
                </div>
              </div>

              <div className="">
                <p className="notification-type">
                  Select any one Notification Type
                </p>
              </div>
              <div className="">
                <Form.Check
                  inline
                  type="radio"
                  label="SMS"
                  value="sms"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  className="custom-radio"
                  checked={notificationType === "sms"}
                  onChange={(e) => {
                    setNotificationType(e.target.value);
                    formik.setFieldValue("notificationType", e.target.value);
                  }}
                  disabled={!editMode}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Email"
                  value="email"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  className="custom-radio"
                  checked={notificationType === "email"}
                  onChange={(e) => {
                    setNotificationType(e.target.value);
                    formik.setFieldValue("notificationType", e.target.value);
                  }}
                  disabled={!editMode}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Push Notification"
                  value="pushNotification"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  className="custom-radio"
                  checked={notificationType === "pushNotification"}
                  onChange={(e) => {
                    setNotificationType(e.target.value);
                    formik.setFieldValue("notificationType", e.target.value);
                  }}
                  disabled={!editMode}
                />
              </div>

              <div className="mt-4">
                {notificationType === "sms" && (
                  <div className="mb-2 login-form">
                    <p className="labelp">Description*</p>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Description"
                      value={formik?.values?.description}
                      onChange={(e) =>
                        formik.setFieldValue("description", e.target.value)
                      }
                      disabled={!editMode}
                      maxLength={150}
                    />
                    {formik?.touched?.description &&
                      formik?.errors?.description && (
                        <div style={{ color: "red" }}>
                          {formik?.errors?.description}
                        </div>
                      )}
                  </div>
                )}
                {notificationType === "email" && (
                  <>
                    <div className="mb-2 login-form">
                      <p className="labelp">Subject*</p>
                      <Form.Control
                        className="selectdisplayed"
                        type="text"
                        placeholder="Subject"
                        value={formik?.values?.subject}
                        onChange={(e) =>
                          formik.setFieldValue("subject", e.target.value)
                        }
                        disabled={!editMode}
                        maxLength={100}
                      />
                      {formik?.touched?.subject && formik?.errors?.subject && (
                        <div style={{ color: "red" }}>
                          {formik?.errors?.subject}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 login-form">
                      <p className="labelp">Body*</p>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Body"
                        value={formik?.values?.body}
                        onChange={(e) =>
                          formik.setFieldValue("body", e.target.value)
                        }
                        disabled={!editMode}
                        maxLength={250}
                      />
                      {formik?.touched?.body && formik?.errors?.body && (
                        <div style={{ color: "red" }}>
                          {formik?.errors?.body}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {notificationType === "pushNotification" && (
                  <>
                    <div className="mb-2 login-form">
                      <p className="labelp">Title*</p>
                      <Form.Control
                        className="selectdisplayed"
                        type="text"
                        placeholder="Title"
                        value={formik?.values?.title}
                        onChange={(e) =>
                          formik.setFieldValue("title", e.target.value)
                        }
                        disabled={!editMode}
                        maxLength={60}
                      />
                      {formik?.touched?.title && formik?.errors?.title && (
                        <div style={{ color: "red" }}>
                          {formik?.errors?.title}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 login-form">
                      <p className="labelp">Description*</p>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Description"
                        value={formik?.values?.pushDescription}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "pushDescription",
                            e.target.value
                          )
                        }
                        maxLength={150}
                        disabled={!editMode}
                      />
                      {formik?.touched?.pushDescription &&
                        formik?.errors?.pushDescription && (
                          <div style={{ color: "red" }}>
                            {formik?.errors?.pushDescription}
                          </div>
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {editMode && location?.state?.deliveryStatus === "Scheduled" && (
            <div className="text-end mt-30 bgt">
              <button
                type="button"
                className="py3 fw-18 gbo continuebtn savebtn cancelbtn me-20"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
              >
                Save
              </button>
            </div>
          )}

          {location?.state?.deliveryStatus === "Sent" && (
            <div className="text-end">
              <button
                type="submit"
                onClick={() => navigate("/notification")}
                className="  border-0 py3 fw-18 gbo ms-auto bgtransparent  addcreatebtn"
              >
                <span>Go back to listing</span>
              </button>
            </div>
          )}
        </form>
      </div>
      {addProductFeatureModal && (
        <BrandChangeModal
          addProductFeatureModal={addProductFeatureModal}
          handleCancel={handleCancel}
          handleModalYes={handleModalYes}
          deleteNotification={deleteNotification}
        />
      )}
    </div>
  );
};

export default UpdateNotification;
