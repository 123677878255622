import { createSlice } from "@reduxjs/toolkit";
import { getAllProductListAPI } from "../../services/getAllProductList";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const getAllProductListSlice = createSlice({
  name: "getAllProductList",
  initialState: data,
  reducers: {
    getAllProductInfo(state) {
      state.isLoading = true;
    },
    getAllProductInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    getAllProductInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    getAllProductInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const getProductHandler = (data) => async (dispatch) => {
  try {
    dispatch(getAllProductAction.getAllProductInfo());
    const response = await getAllProductListAPI(data);
    dispatch(getAllProductAction.getAllProductInfoSuccess(response));
  } catch (e) {
    dispatch(getAllProductAction.getAllProductInfoFailure(e?.response?.data?.message));
  }
};
export default getAllProductListSlice.reducer;
export const getAllProductAction = getAllProductListSlice.actions;
