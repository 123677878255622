import React, { useContext, useEffect, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import SelectInput from "../../common/SelectInput";
import { message } from "antd";
import addbtn from "../../assets/Add_Button.svg";
import uploadimg from "../../assets/uploadicon.svg";
import { useDispatch, useSelector } from "react-redux";
import {fileUploadAction} from "../../redux/action/fileUpload";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";
import deleteicon from "../../assets/deleteicon.svg";
import eye from "../../assets/eye.svg";
import deletebtn from "../../assets/Delete.svg";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useCommonMessage } from "../../common/CommonMessage";
import axios from "axios";
import { API_URL } from "../../services/http";
import { ThemeContext } from "../../common/themeContext";
import { validationSchema, validationSchemaSpanish } from "../../common/Validations";
import Loader from "../Loder/loader";
import { handleSubmitCoiStateWiseGroup } from "./CoiStateWiseCommonPayload";
import { useLocation } from "react-router-dom";

const CoiStateWiseGroup = ({
  fileUploadDataSelector,
  manageSwitch,
  setManageSwitch,
  getAllStatesData,
  selectedCheckbox,
}) => {
  const dispatch = useDispatch();
  const getGroupProductFeatureContent = useSelector(
    (state) => state?.getGroupProductFeatureContent
  );
  const messageApi = useCommonMessage();
  const [fileUploadUrl, setFileUploadUrl] = useState({});
  const [fileUploadUrlPreSignedUrl, setFileUploadUrlPreSignedUrl] = useState(
    {}
  );
  const [selectedStates, setSelectedStates] = useState({});
  const [stateWiseInitialValue, setStateWiseInitialValue] = useState([]);
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const [deleteUploadedFile, setDeleteUploadedFile] = useState("");
  const [imgIndex, setImgIndex] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [handleDeleteDataIndex, setHandleDeleteDataIndex] = useState();
  const [loading, setLoading] = useState(false);

  const { english, spanish } =
    getGroupProductFeatureContent?.data?.data?.content || [];

  const getAdminLoginId = localStorage.getItem("adminId");
  const { brandId } = useContext(ThemeContext);

  const {state} = useLocation()

  useEffect(() => {
    if (fileUploadDataSelector?.data?.data) {
      setFileUploadUrl({
        ...fileUploadUrl,
        [imgIndex]: fileUploadDataSelector?.data?.data?.signedUrl,
      });
    }
  }, [fileUploadDataSelector]);

  useEffect(() => {
    if (fileUploadDataSelector?.data?.data) {
      setFileUploadUrlPreSignedUrl({
        ...fileUploadUrlPreSignedUrl,
        [imgIndex]: fileUploadDataSelector?.data?.data?.preSignedUrl,
      });
    }
  }, [fileUploadDataSelector]);


  useEffect(() => {
    const pageSpanish = manageSwitch
      ? getGroupProductFeatureContent?.data?.data?.content?.spanish?.coi?.state
      : getGroupProductFeatureContent?.data?.data?.content?.english?.coi?.state;


    const isAllState =
      getGroupProductFeatureContent?.data?.data?.content?.isAllState;

    let selected = [];
    let stateSelected = {};
    let urlFile = {};
    if (isAllState === false && pageSpanish?.length) {
      let data = pageSpanish.map((item, index) => {
        stateSelected[index] = item?.stateName;
        urlFile[index] = item?.url;

        if (Array.isArray(item?.stateName)) {
          item.stateName.forEach((record) => {
            selected.push(record);
          });
        } else if (item?.stateName) {
          selected.push(item?.stateName);
        }
        return {
          fileName: item?.fileName || "",
          stateName: item?.stateName || "",
          url: item?.url || "",
          preSigned: item?.preSigned || "",
        };
      });
      const arraySelected = new Set(selected);
      setFileUploadUrl(urlFile);
      setSelectedValue([...arraySelected]);
      setSelectedStates({ ...stateSelected });
      setStateWiseInitialValue(data);
    } else {
      setSelectedValue([]);
      setSelectedStates({});
      setFileUploadUrl({});
      setStateWiseInitialValue([]);
    }
  }, [getGroupProductFeatureContent, manageSwitch]);

  const props = (index, setFieldValue) => {
    const isEnglishCertificate = manageSwitch === false;
    const fieldName = isEnglishCertificate ? `englishCertificate[${index}].file` : `spanishCertificate[${index}].file`;

    return {
      name: fieldName,
      multiple: false,
      accept: '.pdf',
      showUploadList: false,
      beforeUpload(file) {
        const isPDF = file.type === 'application/pdf';
        if (!isPDF) {
          message.error('You can only upload PDF files!');
          return false;
        }
        const isSizeAccepted = file.size / 1024 / 1024 <= 2;
        if (!isSizeAccepted) {
          message.error('File size exceeds 2MB limit!');
          return false;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', getAdminLoginId);
        formData.append("brandId", brandId)
        formData.append(
          "path",
          `${
            "group/" +
            state?.getGroupProductFeatureSelector?.data?.data?.groupCode +
            "/product/" +
            getGroupProductFeatureContent?.data?.data?.productCode +
            "/" +
            state?.fromGroupData?.featureName+"/" + selectedCheckbox+ "/" +(manageSwitch===false?"English":"Spanish")
          }`
        );
        setImgIndex(index);
        setLoading(true);
        axios
          .post(API_URL + '/file/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
          .then((response) => {
            dispatch(fileUploadAction.fileUploadInfoSuccess(response.data));
          })
          .catch((error) => {
            dispatch(fileUploadAction.fileUploadInfoFailure(error));
            message.error('File upload failed. Please try again.');
          })
          .finally(() => {
            setLoading(false);
          });

        dispatch(fileUploadAction.fileUploadInfoReset());
        setFieldValue(fieldName, file);
        return false;
      },
    };
  };
  useEffect(() => {
    if (loading === true) {
      setSelectedStates({})
    }
  }, [loading])

  const handleCancel = () => {
    setAddProductFeatureModal(false);
  };

  const handleModalYes = () => {
    setAddProductFeatureModal(false);
    setDeleteUploadedFile("DeleteFile");

    const setList = manageSwitch === false ? handleDeleteDataIndex?.data?.englishCertificate : handleDeleteDataIndex?.data?.spanishCertificate;
    const updateSets = setList?.filter((_, index) => {
      return (
        index !== handleDeleteDataIndex?.index
      )
    })

    setSelectedStates(prevSelectedStates => {
      const updatedSelectedStates = { ...prevSelectedStates };
      delete updatedSelectedStates[handleDeleteDataIndex?.index];
      return updatedSelectedStates;
    });

    setFileUploadUrl(prev => {
      if (handleDeleteDataIndex?.index == null || !(handleDeleteDataIndex.index in prev)) {
        return prev;
      }
      const { [handleDeleteDataIndex.index]: _, ...rest } = prev;
      const reindexed = Object.entries(rest).reduce((acc, [key, value], idx) => {
        acc[idx] = value;
        return acc;
      }, {});

      return reindexed;
    });

    setFileUploadUrlPreSignedUrl(prev => {
      if (handleDeleteDataIndex?.index == null || !(handleDeleteDataIndex?.index in prev)) {
        return prev;
      }
      const { [handleDeleteDataIndex?.index]: _, ...rest } = prev;
      const reindexed = Object.entries(rest).reduce((acc, [key, value], idx) => {
        acc[idx] = value;
        return acc;
      }, {});

      return reindexed;
    });


    handleDeleteDataIndex?.setFieldValue("englishCertificate", updateSets)
    handleDeleteDataIndex?.setFieldValue("spanishCertificate", updateSets)
  };

  const deleteNotificationHandlers = () => {
    setAddProductFeatureModal(true);
    setDeleteUploadedFile("DeleteFile");
  };

  const handleChange = (value, index) => {
    let changedData = selectedStates[index]?.length
      ? [...selectedStates[index]]
      : [];
    if (changedData?.includes(value)) {
      let dataIndex = changedData?.indexOf(value);
      changedData.splice(dataIndex, 1);
    } else {
      changedData?.push(value);
    }
    selectedStates[index] = changedData;
    setSelectedStates({ ...selectedStates });

    if (selectedValue?.includes(value)) {
      let indexData = selectedValue.findIndex((rec) => rec === value);
      selectedValue.splice(indexData, 1);
    } else {
      selectedValue?.push(value);
    }
    setSelectedValue([...selectedValue]);
  };

  const deleteFile = (setFieldValue, index, values, setFieldTouched) => {
    const { englishCertificate, spanishCertificate } = values;

    const updatedEnglishCertificate = [...englishCertificate];
    const updatedSpanishCertificate = [...spanishCertificate];

    if (updatedEnglishCertificate[index]) {
      updatedEnglishCertificate[index] = {
        ...updatedEnglishCertificate[index],
        fileName: null,
        url: null,
        preSigned: null,
        file: null,
      };

      setFieldTouched(`englishCertificate[${index}].file`, true);
    }

    if (updatedSpanishCertificate[index]) {
      updatedSpanishCertificate[index] = {
        ...updatedSpanishCertificate[index],
        fileName: null,
        url: null,
        preSigned: null,
        file: null,
      };

      setFieldTouched(`spanishCertificate[${index}].file`, true);
    }
    setFieldValue('englishCertificate', updatedEnglishCertificate);
    setFieldValue('spanishCertificate', updatedSpanishCertificate);
  };

  const handleFormSubmit = (values) => {
    handleSubmitCoiStateWiseGroup({manageSwitch,values,messageApi,getGroupProductFeatureContent,fileUploadUrl,fileUploadUrlPreSignedUrl,spanish,dispatch,english})
  };

  const handleEnglishSwitch = () => {
    setManageSwitch(false);
  }

  const handleSpanishSwitch = () => {
    setManageSwitch(true);
  }

  return (
    <>
      {loading === true && (
        <Loader loaderTransForm="loaderTransForm" />
      )}

      <Formik
        enableReinitialize
        initialValues={{
          englishCertificate:
            stateWiseInitialValue?.length > 0
              ? stateWiseInitialValue
              : [{ selectedStates: [], file: null }],

          spanishCertificate:
            stateWiseInitialValue?.length > 0
              ? stateWiseInitialValue
              : [{ selectedStates: [], file: null }],
        }}
        validationSchema={
          manageSwitch === false ? validationSchema : validationSchemaSpanish
        }
        onSubmit={(values, formikBag) => {
          handleFormSubmit(values, formikBag);
        }}
      >
        {({ values, setFieldValue, touched, errors, setFieldTouched }) => (
          <Form>
            {
              <>
                <div className="switches-container mb-30">
                  <div className="mempro" onClick={handleEnglishSwitch}>
                    English
                  </div>
                  <div className="mempro" onClick={handleSpanishSwitch}>
                    Spanish
                  </div>
                  <div
                    className={
                      !manageSwitch ? "switch-wrapper" : "switch-wrapper switchside"
                    }
                  >
                    <div className="switch">
                      {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-20">
                  <p className="heedprodct mb-0">Add Certificate</p>
                </div>
                <FieldArray
                  name={
                    manageSwitch === false
                      ? "englishCertificate"
                      : "spanishCertificate"
                  }
                >
                  {({ push, remove }) => (
                    <div>
                      {manageSwitch === false
                        ? values?.englishCertificate?.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="state-wise btnPosition">
                                <div className="sectionpadding bg-white  mb-30">
                                  <div className="d-lg-flex d-flex-wrap mb-30 align-items-baseline justify-content-between">
                                    <div
                                      className="w-md-50 orderlg pe-2 position-relative mb-3 mb-md-0"
                                      controlId="formBasicEmail"
                                    >
                                      <label>Select*</label>
                                      <SelectInput
                                        key={index}
                                        name={`englishCertificate[${index}].selectedStates`}
                                        options={getAllStatesData.filter(
                                          (record) =>
                                            !selectedValue?.includes(
                                              record.value
                                            )
                                        )}
                                        placeholder={"Select States"}
                                        onSelect={(value) => {
                                          handleChange(value, index);
                                        }}
                                        onDeselect={(value) => {
                                          handleChange(value, index);
                                        }}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            `englishCertificate[${index}].selectedStates`,
                                            newValue
                                          );
                                        }}
                                        value={selectedStates[index]||item?.stateName||item?.selectedStates}
                                        mode={"multiple"}
                                      />
                                      <ErrorMessage
                                        name={`englishCertificate[${index}].selectedStates`}
                                        component="div"
                                        className="error text-danger position-error"
                                      />
                                    </div>

                                    <div className="w-md-50 certifiupload hunset position-relative">
                                      {!(item?.file || item?.fileName) ? (
                                        <Field
                                          name={`englishCertificate[${index}].file`}
                                        >
                                          {() => (
                                            <Dragger
                                              className="allstate"
                                              {...props(index, setFieldValue)}
                                            >
                                              <p className="ant-upload-drag-icon">
                                                <img src={uploadimg} alt="" />
                                              </p>
                                              <p className="ant-upload-text">
                                                <u>Click here</u> or drag &
                                                drop to upload .PDF file
                                              </p>
                                            </Dragger>
                                          )}
                                        </Field>
                                      ) : (
                                        <div className="ant-upload-list ant-upload-list-text">
                                          <span className="ant-upload-list-item-container">
                                            <div className="d-flex gap-2 align-items-center file-icon">
                                              <img src={uploadimg} alt="" />
                                              {item?.file?.name ? `${item?.file?.name.substring(0, 20)}...` : `${item?.fileName?.substring(0, 20)}...`}
                                            </div>
                                            <div className="d-flex gap-2">
                                              <a
                                                href={
                                                  item?.file instanceof
                                                    File ||
                                                    item?.file instanceof Blob
                                                    ? URL.createObjectURL(
                                                      item?.file
                                                    )
                                                    : item?.url
                                                }
                                                target="_blank"
                                              >
                                                <img src={eye} alt="" />
                                              </a>
                                              <div
                                                onClick={() =>
                                                  deleteFile(
                                                    setFieldValue,
                                                    index,
                                                    values,
                                                    setFieldTouched
                                                  )
                                                }
                                                className="cursorPointer"
                                              >
                                                <img
                                                  src={deleteicon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </span>
                                        </div>
                                      )}
                                      {touched.englishCertificate?.[index]
                                        ?.file &&
                                        errors.englishCertificate?.[index]
                                          ?.file ? (
                                        <div className="error text-danger position-error bottom-0">
                                          {errors?.englishCertificate[index]?.file ===
                                            `englishCertificate[${index}].file cannot be null` ?
                                            'File is Required' : null}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  {/* Add Item code */}
                                  <div className="w-100 text-end">
                                    {index ===
                                      values?.englishCertificate?.length -
                                      1 &&
                                      english?.coi?.state?.length > 0 && selectedStates?.[index]?.length > 0 && item?.fileName !== "" && (
                                        <button
                                          className="plusicon faqIcon"
                                          type="button"
                                          onClick={() => {
                                            push({
                                              selectedStates: [],
                                              file: null,
                                            });
                                          }}
                                          disabled={
                                            english?.coi?.state?.length === 0
                                          }
                                        >
                                          <img src={addbtn} alt="" />
                                        </button>
                                      )}
                                    {index !== 0 && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setHandleDeleteDataIndex({
                                            data: values,
                                            index,
                                            remove,
                                            setFieldValue,
                                          });
                                          deleteNotificationHandlers();
                                        }}
                                        className="faqIcon ms-20 plusicon "
                                      >
                                        <img src={deletebtn} alt="Delete" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                {index ===
                                  values?.englishCertificate?.length -
                                  1 && (
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          );
                        })
                        : values?.spanishCertificate?.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="state-wise btnPosition">
                                <div className="sectionpadding bg-white btnPosition mb-30">
                                  <div className="d-lg-flex d-flex-wrap mb-30 align-items-baseline justify-content-between">
                                    <div
                                      className="w-md-50 orderlg pe-2 position-relative mb-3 mb-md-0"
                                      controlId="formBasicEmail"
                                    >
                                      <label>Select* </label>
                                      <SelectInput
                                        key={index}
                                        name={`spanishCertificate[${index}].selectedStates`}
                                        options={getAllStatesData.filter(
                                          (record) =>
                                            !selectedValue?.includes(
                                              record?.value
                                            )
                                        )}
                                        placeholder={"Select States"}
                                        onSelect={(value) =>
                                          handleChange(value, index)
                                        }
                                        onDeselect={(value) =>
                                          handleChange(value, index)
                                        }
                                        value={selectedStates[index]||item?.stateName||item?.selectedStates}
                                        mode={"multiple"}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            `spanishCertificate[${index}].selectedStates`,
                                            newValue
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`spanishCertificate[${index}].selectedStates`}
                                        component="div"
                                        className="error text-danger position-error"
                                      />
                                    </div>
                                    <div className="w-md-50 certifiupload hunset position-relative">
                                      {!(item?.file || item?.fileName) ? (
                                        <Field
                                          name={`spanishCertificate[${index}].file`}
                                        >
                                          {() => (
                                            <Dragger
                                              className="allstate"
                                              {...props(index, setFieldValue)}
                                            >
                                              <p className="ant-upload-drag-icon">
                                                <img src={uploadimg} alt="" />
                                              </p>
                                              <p className="ant-upload-text">
                                                <u>Click here</u> or drag &
                                                drop to upload .PDF file
                                              </p>
                                            </Dragger>
                                          )}
                                        </Field>
                                      ) : (
                                        <div className="ant-upload-list ant-upload-list-text">
                                          <span className="ant-upload-list-item-container">
                                            <div className="d-flex gap-2 align-items-center file-icon">
                                              <img src={uploadimg} alt="" />
                                              {item?.file?.name ? `${item?.file?.name.substring(0, 20)}...` : `${item?.fileName?.substring(0, 20)}...`}
                                            </div>
                                            <div className="d-flex gap-2">
                                              <a
                                                href={
                                                  item?.file instanceof
                                                    File ||
                                                    item?.file instanceof Blob
                                                    ? URL.createObjectURL(
                                                      item?.file
                                                    )
                                                    : item?.url
                                                }
                                                target="_blank"
                                              >
                                                <img src={eye} alt="" />
                                              </a>
                                              <div
                                                onClick={() =>
                                                  deleteFile(
                                                    setFieldValue,
                                                    index,
                                                    values,
                                                    setFieldTouched
                                                  )
                                                }
                                                className="cursorPointer"
                                              >
                                                <img
                                                  src={deleteicon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </span>
                                        </div>
                                      )}
                                      {touched?.spanishCertificate?.[index]
                                        ?.file &&
                                        errors.spanishCertificate?.[index]
                                          ?.file ? (
                                        <div className="error text-danger position-error bottom-0">
                                          {errors?.spanishCertificate[index]?.file ===
                                            `spanishCertificate[${index}].file cannot be null` ?
                                            'File is Required' : null}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  {/* Add Item code */}
                                  <div className="w-100 text-end">
                                    {index ===
                                      values?.spanishCertificate?.length -
                                      1 &&
                                      spanish?.coi?.state?.length > 0 && selectedStates?.[index]?.length > 0 && item?.fileName !== "" && (
                                        <button
                                          className="plusicon faqIcon"
                                          type="button"
                                          onClick={() => {
                                            push({
                                              selectedStates: [],
                                              file: null,
                                            });
                                          }}
                                          disabled={
                                            spanish?.coi?.state?.length === 0
                                          }
                                        >
                                          <img src={addbtn} alt="" />
                                        </button>
                                      )}
                                    {index !== 0 && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setHandleDeleteDataIndex({
                                            data: values,
                                            index,
                                            remove,
                                            setFieldValue,
                                          });
                                          deleteNotificationHandlers();
                                        }}
                                        className="faqIcon ms-20 plusicon "
                                      >
                                        <img src={deletebtn} alt="Delete" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                {index ===
                                  values?.spanishCertificate?.length -
                                  1 && (
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </FieldArray>
              </>
            }
          </Form>
        )}
      </Formik>
      {addProductFeatureModal && (
        <BrandChangeModal
          addProductFeatureModal={addProductFeatureModal}
          handleCancel={handleCancel}
          handleModalYes={handleModalYes}
          deleteUploadedFile={deleteUploadedFile}
        />
      )}
    </>
  );
};

export default CoiStateWiseGroup;
