import { Modal } from "antd";
import React, { useContext } from "react";
import { ThemeContext } from "../../common/themeContext";

export const BrandChangeModal = ({
  isModalOpen,
  handleCancel,
  handleModalYes,
  addProductFeatureModal,
  deleteUploadedFile,
  activeStatus,
  deleteNotification,
  changeClaimStatus,
  isOn,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div>
      <Modal
        className={
          theme === "healthCare" ? "healthcare mx550" : "primecare mx550"
        }
        open={isModalOpen ? isModalOpen : addProductFeatureModal}
        onCancel={handleCancel}
        onOk={handleModalYes}
        footer={[
          <button
            className="  py3 fw-18 gbo continuebtn savebtn cancelbtn me-20"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button className="okbtn continuebtn" onClick={handleModalYes}>
            Yes
          </button>,
        ]}
      >
        {deleteUploadedFile !== "DeleteFile" &&
          deleteUploadedFile !== "DeleteSet" && (
            <div className="mb-1 heedprodct text-center mt-3">
              {addProductFeatureModal &&
              !deleteNotification &&
              !deleteUploadedFile
                ? "Are you sure?"
                : !deleteNotification &&
                  !deleteUploadedFile &&
                  "Are you sure you want to switch the brand?"}
            </div>
          )}

        {deleteUploadedFile === "DeleteSet" &&
          deleteUploadedFile !== "DeleteFile" && (
            <div className="mb-1 heedprodct text-center mt-3">
              {"Are you sure you want to delete set?"}
            </div>
          )}

        {deleteUploadedFile === "DeleteQuestion" && (
          <div className="mb-1 heedprodct text-center mt-3">
            {"Are you sure you want to delete Question?"}
          </div>
        )}

        {deleteUploadedFile === "DeleteFile" ? (
          <div className="mb-1 heedprodct text-center mt-3">
            Are you sure you want to delete the file?
          </div>
        ) : deleteUploadedFile !== "DeleteSet" ? (
          <p className="text-center">
            {activeStatus === true
              ? `This will turn the feature off for this product`
              : activeStatus === false
              ? "This will turn the feature on for this product"
              : deleteNotification
              ? "Are you sure you want to delete notification?"
              : changeClaimStatus === "manageClaimStatus"
              ? `This will turn ${isOn ? "off" : "on"} the claim feature`
              : deleteUploadedFile !== "DeleteQuestion" &&
                "Your unsaved changes will be lost."}
          </p>
        ) : null}
        <hr />
      </Modal>
    </div>
  );
};
