import React, { useContext, useEffect, useState } from "react";
import { message, Upload } from "antd";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import uploadimg from "../../assets/uploadicon.svg";
import eye from "../../assets/eye.svg";
import deleteicon from "../../assets/deleteicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { fileUploadAction } from "../../redux/action/fileUpload";
import {
  addProductFeatureAction,
  addProductFeaturesHandler,
} from "../../redux/action/addProductFeaturesSlice";
import { getProductFeaturesAction } from "../../redux/action/getproductFeaturesSlice";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";
import { convert } from "html-to-text";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import { useCommonMessage } from "../../common/CommonMessage";
import axios from "axios";
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnStrikeThrough,
  BtnUnderline,
  Editor,
  EditorProvider,
  HtmlButton,
  Toolbar,
} from "react-simple-wysiwyg";
import { API_URL } from "../../services/http";
import {
  BtnAlignCenter,
  BtnAlignLeft,
  BtnAlignRight,
  BtnImageUpload,
  BtnSubscript,
  BtnSuperscript,
} from "../Html_Editor/BtnFontFamily";
import { ThemeContext } from "../../common/themeContext";
import { DraggerPayload } from "./DraggerPayload";
import Loader from "../Loder/loader";
import { useLocation } from "react-router-dom";

const Dragger = ({ deleteUploadedFile, setDeleteUploadedFile, state }) => {
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const [manageSwitch, setManageSwitch] = useState(false);
  const [handleSwitchToast, setHandleSwitchToast] = useState(false);
  const [fileUploaded, setFileUploaded] = useState("");
  const [spanishFileUpload, setSpanishFileUpload] = useState("");
  const [plainText, setPlainText] = useState("");
  const [plainTextSpanish, setPlaintextSpanish] = useState("");
  const [loading, setLoading] = useState(false);

  const messageApi = useCommonMessage();
  const dispatch = useDispatch();

  const fileUploadDataSelector = useSelector((state) => state?.fileUploadData);

  const addProductFeaturesSelector = useSelector(
    (state) => state?.addProductFeatures
  );
  const getProductFeatureSelector = useSelector(
    (state) => state?.getProductFeatureContentMain
  );

  let getProductData = getProductFeatureSelector?.data?.data;
  let fileUploadData = fileUploadDataSelector?.data?.data;

  const getAdminLoginId = localStorage.getItem("adminId");
  const { brandId } = useContext(ThemeContext);

  // Reset Actions
  const resetActions = () => {
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset());
  };

  const { Dragger } = Upload;

  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    showUploadList: false,
    async beforeUpload(file) {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error("You can only upload PDF files!");
        setFileUploaded("");
        return false;
      }
      const isSizeAccepted = file.size / 1024 / 1024 <= 2;
      if (!isSizeAccepted) {
        message.error("File size exceeds 2MB limit!");
        setFileUploaded("");
        return false;
      }
      if (isPDF && isSizeAccepted) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", Number(getAdminLoginId));
        formData.append("brandId", brandId);
        formData.append(
          "path",
          `${"product/" + getProductFeatureSelector?.data?.data?.productCode}/${
            state?.item?.featureName
          }/${manageSwitch === false?"English":"Spanish"}`
        );
        setLoading(true);
        axios
          .post(API_URL + "/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            dispatch(fileUploadAction.fileUploadInfoSuccess(response.data));
            if (manageSwitch === false) {
              setFileUploaded(file?.name);
            } else {
              setSpanishFileUpload(file?.name);
            }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            dispatch(fileUploadAction.fileUploadInfoFailure(error));
          })
          .finally(() => {
            setLoading(false);
          });
        if (manageSwitch === false) {
          setFileUploaded(file?.name);
        } else {
          setSpanishFileUpload(file?.name);
        }
      }
      return false;
    },
  };

  const handleCancel = () => {
    setAddProductFeatureModal(false);
  };

  // Delete Modal Functionality
  const handleModalYes = () => {
    if (manageSwitch === false) {
      setAddProductFeatureModal(false);
      setFileUploaded("");
      resetActions();
      let payload = {
        productId: getProductData?.productId,
        featureId: getProductData?.refFeatureId,
        content: {
          html: false,
          english: {
            file: {
              url: "",
              name: "",
              isTrue: true,
              preSigned: "",
            },
            html: {
              isTrue: true,
              html_content: "",
            },
          },
          pdf_url: true,
          spanish: {
            file: {
              url: getProductData?.content?.spanish?.file?.url || "",
              name: getProductData?.content?.spanish?.file?.name || "",
              isTrue: getProductData?.content?.spanish?.file?.isTrue || true,
              preSigned: getProductData?.content?.spanish?.file?.preSigned,
            },
            html: {
              isTrue: true,
              html_content: "",
            },
          },
        },
      };
      dispatch(addProductFeaturesHandler(payload));
    } else if (manageSwitch === true) {
      setAddProductFeatureModal(false);
      setSpanishFileUpload("");
      resetActions();
      let payload2 = {
        productId: getProductData?.productId,
        featureId: getProductData?.refFeatureId,
        content: {
          html: false,
          english: {
            file: {
              url: getProductData?.content?.english?.file?.signedUrl || "",
              name: getProductData?.content?.english?.file?.name || "",
              preSigned: getProductData?.content?.english?.file?.preSigned,
              isTrue: true,
            },
            html: {
              isTrue: true,
              html_content: "",
            },
          },
          pdf_url: true,
          spanish: {
            file: {
              url: "",
              name: "",
              isTrue: true,
              preSigned: "",
            },
            html: {
              isTrue: true,
              html_content: "",
            },
          },
        },
      };
      dispatch(addProductFeaturesHandler(payload2));
    }
  };

  const deleteFile = () => {
    setAddProductFeatureModal(true);
    setDeleteUploadedFile("DeleteFile");
  };
  const handleFormSubmit = (values) => {
    DraggerPayload({
      manageSwitch,
      getProductData,
      fileUploadData,
      values,
      plainText,
      fileUploaded,
      plainTextSpanish,
      spanishFileUpload,
      dispatch,
    });
  };

  useEffect(() => {
    if (fileUploadData) {
      messageApi.open({
        type: "success",
        content: fileUploadDataSelector?.data?.message,
      });
      setHandleSwitchToast(true);
    }
  }, [fileUploadDataSelector]);

  useEffect(() => {
    if (addProductFeaturesSelector?.data?.data) {
      messageApi.open({
        type: "success",
        content: addProductFeaturesSelector?.data?.message,
      });
      dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
      let payload1 = {
        productId: getProductData?.productId,
        refFeatureId: getProductData?.refFeatureId,
      };
      dispatch(getProductFeatureContentHandler(payload1));
    }
  }, [addProductFeaturesSelector]);

  const handleEnglishSwitch = () => {
    if (handleSwitchToast && fileUploadData) {
      messageApi.open({
        type: "warning",
        content: "Please Save File First!!",
      });
      setManageSwitch(true);
    } else {
      setManageSwitch(false);
    }
  };

  const handleSpanishSwitch = () => {
    if (handleSwitchToast && fileUploadData) {
      messageApi.open({
        type: "warning",
        content: "Please Save File First!!",
      });
      setManageSwitch(false);
    } else {
      setManageSwitch(true);
    }
  };

  useEffect(() => {
    if (manageSwitch === false) {
      setPlainText(getProductData?.content?.english?.html?.html_content);
      setPlaintextSpanish(getProductData?.content?.spanish?.html?.html_content);
    } else if (manageSwitch === true) {
      setPlaintextSpanish(getProductData?.content?.spanish?.html?.html_content);
      setPlainText(getProductData?.content?.english?.html?.html_content);
    }
  }, [getProductFeatureSelector, manageSwitch]);

  const onChange = (e) => {
    setPlainText(e.target.value);
  };
  const onChangeSpanish = (e) => {
    setPlaintextSpanish(e.target.value);
  };
  return (
    <>
      {(loading === true ||
        addProductFeaturesSelector?.isLoading === true ||
        getProductFeatureSelector?.isLoading === true) && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="switches-container mb-30">
        <div className="mempro" onClick={handleEnglishSwitch}>
          English
        </div>
        <div className="mempro" onClick={handleSpanishSwitch}>
          Spanish
        </div>
        <div
          className={
            !manageSwitch ? "switch-wrapper" : "switch-wrapper switchside"
          }
        >
          <div className="switch">
            {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          file_upload: "",
          html_editor: plainText || "",
          html_editor_spanish: plainTextSpanish || "",
        }}
        onSubmit={(values, formikBag) => {
          handleFormSubmit(values, formikBag);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="table-padding tabbing mb-40 btnPosition">
              {manageSwitch === false ? (
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className=""
                >
                  <Tab eventKey="home" title="Upload File">
                    {convert(plainText) !== "" &&
                    convert(plainText).trim() !== "" ? (
                      <div className="removehtml">
                        Please Remove HTML in HTML Editor
                      </div>
                    ) : (
                      <div>
                        {!(
                          fileUploaded ||
                          getProductData?.content?.english?.file?.url
                        ) ? (
                          <>
                            <Field name="file_upload">
                              {({ field }) => (
                                <Dragger {...props}>
                                  <p className="ant-upload-drag-icon">
                                    <img src={uploadimg} alt="" />
                                  </p>
                                  <p className="ant-upload-text">
                                    <u>Click here</u> or drag & drop to upload
                                    .PDF file
                                  </p>
                                </Dragger>
                              )}
                            </Field>
                            <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                              <div className="fs-16">
                                Supported formats : PDF
                              </div>
                              <div className="fs-16">
                                Maximum file size : 2 MB
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="ant-upload-list ant-upload-list-text">
                            <span className="ant-upload-list-item-container">
                              <div className="d-flex gap-2 align-items-center file-icon">
                                <img src={uploadimg} alt="" />
                                {fileUploaded ||
                                  getProductData?.content?.english?.file?.name}
                              </div>
                              <div className="d-flex gap-2">
                                <a
                                  href={
                                    fileUploadData?.signedUrl ||
                                    getProductData?.content?.english?.file?.url
                                  }
                                  target="_blank"
                                >
                                  <img src={eye} alt="" />
                                </a>
                                <div
                                  onClick={deleteFile}
                                  className="cursorPointer"
                                >
                                  <img src={deleteicon} alt="" />
                                </div>
                              </div>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {fileUploadDataSelector?.data !== null && (
                      <div className="text-end">
                        <button
                          type="submit"
                          className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="profile" title="HTML Editor">
                    {!(
                      fileUploadData ||
                      getProductData?.content?.english?.file?.url ||
                      getProductData?.content?.spanish?.file?.url
                    ) ? (
                      <>
                        <EditorProvider>
                          <Editor value={plainText} onChange={onChange}>
                            <Toolbar>
                              <BtnBold />
                              <BtnItalic />
                              <BtnUnderline />
                              <HtmlButton />
                              <BtnBulletList />
                              <BtnNumberedList />
                              <BtnStrikeThrough />
                              <BtnAlignCenter />
                              <BtnAlignLeft />
                              <BtnAlignRight />
                              <BtnSuperscript />
                              <BtnSubscript />
                              <BtnImageUpload />
                            </Toolbar>
                          </Editor>
                        </EditorProvider>

                        <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                          <div className="fs-16">
                            Note: {"</>"} Click this button before adding HTML
                            text.
                          </div>
                        </div>
                        {convert(plainText).trim() !== "" && (
                          <div className="text-end">
                            <button
                              type="submit"
                              className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="removehtml">
                        Please Remove File in uploaded file
                      </div>
                    )}
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className=""
                >
                  <Tab eventKey="home" title="Upload File">
                    {(convert(plainTextSpanish) !== "" &&
                      convert(plainTextSpanish).trim() !== "") ||
                    convert(plainText)?.trim() !== "" ? (
                      <div className="removehtml">
                        Please Remove HTML in HTML Editor
                      </div>
                    ) : (
                      <div>
                        {!(
                          spanishFileUpload ||
                          getProductData?.content?.spanish?.file?.url
                        ) ? (
                          <>
                            <Field name="file_upload">
                              {({ field }) => (
                                <Dragger {...props}>
                                  <p className="ant-upload-drag-icon">
                                    <img src={uploadimg} alt="" />
                                  </p>
                                  <p className="ant-upload-text">
                                    <u>Click here</u> or drag & drop to upload
                                    .PDF file spanish
                                  </p>
                                </Dragger>
                              )}
                            </Field>
                            <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                              <div className="fs-16">
                                Supported formats : PDF
                              </div>
                              <div className="fs-16">
                                Maximum file size : 2 MB
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="ant-upload-list ant-upload-list-text">
                              <span className="ant-upload-list-item-container">
                                <div className="d-flex gap-2 align-items-center file-icon">
                                  <img src={uploadimg} alt="" />
                                  {spanishFileUpload ||
                                    getProductData?.content?.spanish?.file
                                      ?.name}
                                </div>
                                <div className="d-flex gap-2">
                                  <a
                                    href={
                                      fileUploadData?.signedUrl ||
                                      getProductData?.content?.spanish?.file
                                        ?.url
                                    }
                                    target="_blank"
                                  >
                                    <img src={eye} alt="" />
                                  </a>
                                  <div
                                    onClick={deleteFile}
                                    className="cursorPointer"
                                  >
                                    <img src={deleteicon} alt="" />
                                  </div>
                                </div>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {fileUploadDataSelector?.data !== null && (
                      <div className="text-end">
                        <button
                          type="submit"
                          className=" sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="profile" title="HTML Editor">
                    {!(
                      fileUploadData ||
                      getProductData?.content?.spanish?.file?.url ||
                      getProductData?.content?.english?.file?.url
                    ) ? (
                      <>
                        <EditorProvider>
                          <Editor
                            value={plainTextSpanish}
                            onChange={onChangeSpanish}
                          >
                            <Toolbar>
                              <BtnBold />
                              <BtnItalic />
                              <BtnUnderline />
                              <HtmlButton />
                              <BtnBulletList />
                              <BtnNumberedList />
                              <BtnStrikeThrough />
                              <BtnAlignCenter />
                              <BtnAlignLeft />
                              <BtnAlignRight />
                              <BtnSuperscript />
                              <BtnSubscript />
                              <BtnImageUpload />
                            </Toolbar>
                          </Editor>
                        </EditorProvider>
                        <div className="mt-3 d-flex justify-content-between textgrey  w-100">
                          <div className="fs-16">
                            Note: {"</>"} Click this button before adding HTML
                            text.
                          </div>
                        </div>
                        {convert(plainTextSpanish).trim() !== "" && (
                          <div className="text-end">
                            <button
                              type="submit"
                              className="sbg border-0 fw-18 gbo continuebtn savebtn disclaimerBtn"
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="removehtml">
                        Please Remove File in uploaded file
                      </div>
                    )}
                  </Tab>
                </Tabs>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {addProductFeatureModal && (
        <BrandChangeModal
          addProductFeatureModal={addProductFeatureModal}
          handleCancel={handleCancel}
          handleModalYes={handleModalYes}
          deleteUploadedFile={deleteUploadedFile}
        />
      )}
    </>
  );
};

export default Dragger;
