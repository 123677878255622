import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { pathName } from "../../utils/commonUtils";
import { groupsColumns } from "./groupUtils";
import { ThemeContext } from "../../common/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroupsHandler } from "../../redux/action/getAllGroups";
import Loader from "../../components/Loder/loader";
import CommonTable from "../../common/CommonTable";
import { BreadCrum } from "../../common";

const Groups = () => {
  const [groupsData, setGroupsData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 100,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 15, 20],
    className: "order-pagination",
    locale: { items_per_page: "" },
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { brandId } = useContext(ThemeContext);

  const columns = groupsColumns(navigate);
  const getAllGroupsList = useSelector((state) => state?.getAllGroups);

  const handleGroupsData = (offSet = 1, limit = 10) => {
    window.scrollTo(0, 0);
    const payload = { offSet, limit, brandId: brandId };
    if (brandId === 1 || brandId === 2) {
      dispatch(getAllGroupsHandler(payload));
    }
  };

  useEffect(() => {
    handleGroupsData();
  }, [brandId]);

  useEffect(() => {
    if (getAllGroupsList?.data?.data) {
      const data = getAllGroupsList?.data?.data?.map((item, index) => {
        return {
          groupCode: item?.group_Code,
          groupName: item?.name,
          productCode: item?.product_Codes,
          productName: item?.product_Names,
          companyName: item?.organization_Name,
          groupId: item?.id,
        };
      });
      setGroupsData(data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: getAllGroupsList?.data?.totalElements,
        current: getAllGroupsList?.data?.pageNumber,
        pageSize: getAllGroupsList?.data?.pageSize,
      }));
    }
  }, [getAllGroupsList]);

  return (
    <>
      {getAllGroupsList?.isLoading === true && (
        <Loader loaderTransform="loaderTransform" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Groups</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: pathName(location), active: true },
            ]}
          />

          <div className="table-padding">
            <div className="headtitle">
              <p className="mb-0 heedprodct">Groups</p>
            </div>
            <hr className="m-0" />
            <div className="table-responsive overfloauto">
              <CommonTable
                columns={columns}
                body={groupsData}
                pagination={pagination}
                handlePageChange={handleGroupsData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Groups;
