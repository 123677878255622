
export const usersListColumns = (navigate) => [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: {
      compare: (a, b) => a.firstName.localeCompare(b.firstName),
    },
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    sorter: {
      compare: (a, b) => a.lastName.localeCompare(b.lastName),
    },
  },
  {
    title: "Email ID",
    dataIndex: "emailId",
    key: "emailId",
    className: "emailtable",
    sorter: {
      compare: (a, b) => a.emailId.localeCompare(b.emailId),
    },
  },
  {
    title: "Member ID",
    dataIndex: "memberId",
    key: "memberId",
    sorter: {
      compare: (a, b) => a.memberId - b.memberId,
    },
  },
  {
    title: "Group Name",
    dataIndex: "groupName",
    key: "groupName",
    sorter: {
      compare: (a, b) => a.groupName.localeCompare(b.groupName),
    },
  },
  {
    title: "Group Code",
    dataIndex: "groupCode",
    key: "groupCode",
    sorter: {
      compare: (a, b) => a.groupCode - b.groupCode,
    },
  },
  {
    title: "Product Code",
    dataIndex: "productCode",
    key: "productCode",
    sorter: {
      compare: (a, b) => {
        if (!isNaN(a.productCode) && !isNaN(b.productCode)) {
          return a.productCode - b.productCode;
        } else {
          return a.productCode.toString().localeCompare(b.productCode.toString());
        }
      },
    }
  },
  {
    title: "Action",
    key: "action",
    render: (key) => {
      return (
        <div className="action-icons">
          <div
          >
            <button className="manage" onClick={() => navigate('/userTemp', { state: key })}>
              View
            </button>
          </div>
        </div>
      );
    },
  },
];




export const userDetailsColumns = [
  {
    title: 'Group Name',
    dataIndex: 'userDetailsGroupName',
    key: "userDetailsGroupName",
  },
  {
    title: 'Group Code ',
    dataIndex: 'userDetailsGroupId',
    key: "userDetailsGroupId",
  },
  {
    title: 'Product Code',
    dataIndex: 'userDetailsProductCode',
    key: "userDetailsProductCode",
  },
];