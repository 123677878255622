import React, { useContext, useEffect, useState } from "react";
import phone from "../../assets/phone.svg";
import email from "../../assets/email.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../common/themeContext";
import { getUserDetailsHandler } from "../../redux/action/getUserDetailsSlice";
import CommonTable from "../../common/CommonTable";
import { userDetailsColumns } from "./UsersUtils";
import { BreadCrum } from "../../common";
import Loader from "../Loder/loader";

const Usertemp = () => {
  const [userDetailsdata, setUserDetailsData] = useState([])
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const { brandId } = useContext(ThemeContext);
  const { state } = location;

  const userDetailsSelector = useSelector((state) => state?.getUserDetails)
  console.log(userDetailsSelector,"userDetailsSelector")

  useEffect(() => {
    let payload = {
      userId: state?.userId,
      brandId: brandId
    };
    if (brandId === 1) {
      dispatch(getUserDetailsHandler(payload));
    } else if (brandId === 2) {
      dispatch(getUserDetailsHandler(payload));
    }
  }, [brandId, state])


  useEffect(() => {
    const data = userDetailsSelector?.data?.data?.groups?.map((item) => {
      return {
        userDetailsGroupName: item?.groupName || "N/A",
        userDetailsGroupId: item?.caspioEmployerGroupCode || "N/A",
        userDetailsProductCode: item?.productCodes?.map(item => `${item}`).join(", ") || "N/A"
      }
    })
    setUserDetailsData(data)
  }, [userDetailsSelector])

  return (
    <>
      {userDetailsSelector?.isLoading === true && <Loader loaderTransform="loaderTransform" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Users</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: BreadCrum.USERS, onClick: () => navigate('/user') },
              { text: `${userDetailsSelector?.data?.data?.firstName + " " + userDetailsSelector?.data?.data?.lastName}`, active: true },
            ]}
          />
          <div className="usercard mb-30">
            <div className="usernamedetail">
              <div className="userimg">
                <div className="userintial">
                  {userDetailsSelector?.data?.data?.firstName?.charAt(0).toUpperCase() + userDetailsSelector?.data?.data?.lastName?.charAt(0).toUpperCase()}
                </div>
              </div>
              <div className="username">{userDetailsSelector?.data?.data?.firstName + " " + userDetailsSelector?.data?.data?.lastName}</div>
              <span className="userid">{userDetailsSelector?.data?.data?.caspioUserId || "N/A"}</span>
            </div>
            <hr className="m-0" />

            {/* Caspio Details */}

            <div className="userotherdetail">
              <div className="userpersonal">
                <div className="detailtitle mb-20">CASPIO DETAILS</div>
                <div className="line60 d-xxl-flex responsiveflex mb-20">
                  <div className="minwidthfix">
                    <div className="productname mb-10">DOB </div>
                    <div className="producttitle ">{userDetailsSelector?.data?.data?.dateOfBirth || "N/A"}</div>
                  </div>
                  {/* <div className="verticalline mx-100"></div> */}
                  <div className="minwidthfix">
                    <div className="productname mb-10">Gender </div>
                    <div className="producttitle ">{userDetailsSelector?.data?.data?.gender?.charAt(0).toUpperCase() + userDetailsSelector?.data?.data?.gender?.slice(1) ?? "N/A"}</div>
                  </div>
                  {/* <div className="verticalline mx-100"></div> */}
                  <div className="minwidthfix">
                    <div className="productname mb-10">Address </div>
                    <div className="producttitle ">{userDetailsSelector?.data?.data?.address || "N/A"}</div>
                  </div>
                <div className="minwidthfix">
                    <div className="mb-2 d-flex"><span><img src={phone} alt="" className="me-3" /></span><span className="producttitle">{userDetailsSelector?.data?.data?.phoneNumber?? "N/A"}</span></div>
                    <div className="d-flex"><span><img src={email} alt="" className="me-3" /></span><span className="producttitle">{userDetailsSelector?.data?.data?.emailAddress ?? "N/A"}</span></div>
                  </div>
                  {/* <div className="verticalline mx-100"></div> */}
                  <div className="minwidthfix">
                    <div className="productname mb-10">Family Id</div>
                    <div className="producttitle ">{userDetailsSelector?.data?.data?.familyId ||  "N/A"}</div>
                  </div>
                  {/* <div className="verticalline mx-100"></div> */}
                  <div className="minwidthfix">
                    <div className="productname mb-10">Sequence Number</div>
                    <div className="producttitle ">{userDetailsSelector?.data?.data?.sequenceNumber || "N/A"}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* Member Provided Details */}
            <hr className="m-0" />
            <div className="userotherdetail">
              <div className="userpersonal">
                <div className="detailtitle mb-20">MEMBER PROVIDED DETAILS</div>
                <div className="line60 d-xxl-flex responsiveflex">
                  <div className="minwidthfix">
                    <div className="productname mb-10">Name</div>
                    <div className="producttitle ">{userDetailsSelector?.data?.data?.memberProvidedFirstName + " " + userDetailsSelector?.data?.data?.memberProvidedLastName || "N/A"}</div>
                  </div>
                  {/* <div className="verticalline mx-100"></div> */}
                  <div className="minwidthfix">
                    <div className="productname mb-10">Address </div>
                    <div className="producttitle ">{userDetailsSelector?.data?.data?.memberProvidedAddress}</div>
                  </div>
                  {/* <div className="verticalline mx-100"></div> */}
                  <div className="minwidthfix">
                    <div className="mb-2 d-flex"><span><img src={phone} alt="" className="me-3" /></span><span className="producttitle">{userDetailsSelector?.data?.data?.memberProvidedPhone}</span></div>
                    <div className="d-flex"><span><img src={email} alt="" className="me-3" /></span><span className="producttitle">{userDetailsSelector?.data?.data?.memberProvidedEmail ?? "N/A"}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-padding ">
            <div className="headtitle">
              <p className="mb-0 heedprodct">Groups</p>
            </div>
            <hr className="m-0" />
            <div className="table-responsive overfloauto">
              <CommonTable
                body={userDetailsdata}
                columns={userDetailsColumns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Usertemp;
