import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const CustomBreadcrumb = ({ items }) => {
  return (
    <Breadcrumb className="mb-40">
      {items.map((item, index) => (
        <Breadcrumb.Item
          key={index}
          onClick={item.onClick}
          active={item.active}
          href={item.href}
        >
          {item.text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
