import { createSlice } from '@reduxjs/toolkit';
import { getNotificationsListAPI } from '../../services/getNotificationsList';

const data = {
  isLoading: false,
  error: '',
  message: '',
  data: null,
};

const getNotificationsSlice = createSlice({
  name: 'getNotificationsList',
  initialState: data,
  reducers: {
    getNotificationsListInfo(state) {
      state.isLoading = true;
    },
    getNotificationsListInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = '';
    },
    getNotificationsListInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    getNotificationsListInfoReset(state) {
      state.isLoading = false;
      state.message = '';
      state.data = null;
      state.error = '';
    },
  },
});

export const getNotificationsListsHandler = (data) => async (dispatch) => {
  try {
    dispatch(getNotificationsAction.getNotificationsListInfo());
    const response = await getNotificationsListAPI(data);
    dispatch(getNotificationsAction.getNotificationsListInfoSuccess(response));
  } catch (e) {
    dispatch(
      getNotificationsAction.getNotificationsListInfoFailure(
        e?.response?.data?.message
      )
    );
  }
};
export default getNotificationsSlice.reducer;
export const getNotificationsAction = getNotificationsSlice.actions;
