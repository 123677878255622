import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import React from 'react';

export const SearchSelect = ({
  onChange,
  onFocus,
  onBlur,
  onSearch,
  options,
  placeholder,
  isDisabled,
  error,
  id, value
}) => {
  const handleChange = (selectedProductName) => {
    onChange(selectedProductName);
  };

  return (
    <>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder={placeholder}
        className="form-select p-0 w-100"
        optionFilterProp="children"
        onChange={handleChange}
        onFocus={onFocus}
        id={id}
        value={value}
        onBlur={onBlur}
        onSearch={onSearch}
        suffixIcon={null}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={isDisabled}
      >
        {options.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      {error && <div className="error">{error}</div>}
    </>
  );
};
