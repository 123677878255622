import { addProductFeaturesHandler } from "../../redux/action/addProductFeaturesSlice";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { getProductFeaturesAction } from "../../redux/action/getproductFeaturesSlice";
import { updateGroupProductFeaturesHandler } from "../../redux/action/updateGroupProductFeature";

// COI all state product wise handleSubmit
export const handleSubmitProductCoiAllState = ({
  manageSwitch,
  state,
  selectedCheckbox,
  fileUploadDataSelector,
  fileUploaded,
  getSpanishFileUpload,
  dispatch,
  getEnglishFileUpload,
  spanishFileUpload,
}) => {
  if (manageSwitch === false) {
    let payload = {
      productId: state?.productId || state?.item?.productId,
      featureId: state?.refFeatureId || state?.item?.refFeatureId,
      content: {
        coi: true,
        isAllState: selectedCheckbox === "allStates" ? true : false,
        english: {
          coi: {
            state:
              [
                {
                  url: fileUploadDataSelector?.data?.data?.signedUrl,
                  fileName: fileUploaded,
                  stateName: selectedCheckbox === "allStates" ? true : [],
                  preSigned: fileUploadDataSelector?.data?.data?.preSignedUrl,
                },
              ] || [],
          },
        },
        spanish: {
          coi: {
            state: getSpanishFileUpload?.url
              ? [
                  {
                    url: getSpanishFileUpload?.url,
                    fileName: getSpanishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getSpanishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
      },
    };
    dispatch(addProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
  } else if (manageSwitch === true) {
    let payload = {
      productId: state?.productId || state?.item?.productId,
      featureId: state?.refFeatureId || state?.item?.refFeatureId,
      content: {
        coi: true,
        isAllState: selectedCheckbox === "allStates" ? true : false,
        english: {
          coi: {
            state: getEnglishFileUpload?.url
              ? [
                  {
                    url: getEnglishFileUpload?.url,
                    fileName: getEnglishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getEnglishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
        spanish: {
          coi: {
            state: [
              {
                url: fileUploadDataSelector?.data?.data?.signedUrl,
                fileName: spanishFileUpload,
                stateName: selectedCheckbox === "allStates" ? true : [],
                preSigned: fileUploadDataSelector?.data?.data?.preSignedUrl,
              },
            ],
          },
        },
      },
    };
    dispatch(addProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
  }
};

// COI all state group wise handleSubmit
export const handleSubmitGroupCoiAllState = ({
  manageSwitch,
  getGroupProductFeatureContent,
  selectedCheckbox,
  fileUploadDataSelector,
  fileUploaded,
  getGroupSpanishFileUpload,
  dispatch,
  getGroupEnglishFileUpload,
  spanishFileUpload,
}) => {
  if (manageSwitch === false) {
    let payload = {
      id: getGroupProductFeatureContent?.data?.data?.id,
      content: {
        coi: true,
        isAllState: selectedCheckbox === "allStates" ? true : false,
        english: {
          coi: {
            state:
              [
                {
                  url: fileUploadDataSelector?.data?.data?.signedUrl,
                  fileName: fileUploaded,
                  stateName: selectedCheckbox === "allStates" ? true : [],
                  preSigned: fileUploadDataSelector?.data?.data?.preSignedUrl,
                },
              ] || [],
          },
        },
        spanish: {
          coi: {
            state: getGroupSpanishFileUpload?.url
              ? [
                  {
                    url: getGroupSpanishFileUpload?.url,
                    fileName: getGroupSpanishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getGroupSpanishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
      },
    };
    dispatch(updateGroupProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
  } else if (manageSwitch === true) {
    let payload = {
      id: getGroupProductFeatureContent?.data?.data?.id,
      content: {
        coi: true,
        isAllState: selectedCheckbox === "allStates" ? true : false,
        english: {
          coi: {
            state: getGroupEnglishFileUpload?.url
              ? [
                  {
                    url: getGroupEnglishFileUpload?.url,
                    fileName: getGroupEnglishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getGroupEnglishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
        spanish: {
          coi: {
            state: [
              {
                url: fileUploadDataSelector?.data?.data?.signedUrl,
                fileName: spanishFileUpload,
                stateName: selectedCheckbox === "allStates" ? true : [],
                preSigned: fileUploadDataSelector?.data?.data?.preSignedUrl,
              },
            ],
          },
        },
      },
    };
    dispatch(updateGroupProductFeaturesHandler(payload));
    dispatch(fileUploadAction.fileUploadInfoReset());
  }
};

// COI all state product wise handleDelete
export const handleDeleteAllStateProductWise = ({
  manageSwitch,
  state,
  getSpanishFileUpload,
  selectedCheckbox,
  setAddProductFeatureModal,
  setDeleteUploadedFile,
  setFileUploaded,
  dispatch,
  getEnglishFileUpload,
  setSpanishFileUpload,
}) => {
  if (manageSwitch === false) {
    let payload = {
      productId: state?.productId || state?.item?.productId,
      featureId: state?.refFeatureId || state?.item?.refFeatureId,
      content: {
        coi: false,
        isAllState: true,
        english: {
          coi: {
            state: [],
          },
        },
        spanish: {
          coi: {
            state: getSpanishFileUpload?.url
              ? [
                  {
                    url: getSpanishFileUpload?.url,
                    fileName: getSpanishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getSpanishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
      },
    };
    setAddProductFeatureModal(false);
    setDeleteUploadedFile("DeleteFile");
    setFileUploaded("");
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset());
    dispatch(addProductFeaturesHandler(payload));
  } else if (manageSwitch === true) {
    let payload = {
      productId: state?.productId || state?.item?.productId,
      featureId: state?.refFeatureId || state?.item?.refFeatureId,
      content: {
        coi: false,
        isAllState: true,
        english: {
          coi: {
            state: getEnglishFileUpload?.url
              ? [
                  {
                    url: getEnglishFileUpload?.url,
                    fileName: getEnglishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getEnglishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
        spanish: {
          coi: {
            state: [],
          },
        },
      },
    };
    setAddProductFeatureModal(false);
    setDeleteUploadedFile("DeleteFile");
    setSpanishFileUpload("");
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset());
    dispatch(addProductFeaturesHandler(payload));
  }
};

// COI all state group wise handleDelete
export const handleDeleteAllStateGroupWise = ({
  manageSwitch,
  getGroupProductFeatureContent,
  getGroupSpanishFileUpload,
  selectedCheckbox,
  setAddProductFeatureModal,
  setDeleteUploadedFile,
  setFileUploaded,
  dispatch,
  getGroupEnglishFileUpload,
  setSpanishFileUpload,
}) => {
  if (manageSwitch === false) {
    let payload = {
      id: getGroupProductFeatureContent?.data?.data?.id,
      content: {
        coi: false,
        isAllState: true,
        english: {
          coi: {
            state: [],
          },
        },
        spanish: {
          coi: {
            state: getGroupSpanishFileUpload?.url
              ? [
                  {
                    url: getGroupSpanishFileUpload?.url,
                    fileName: getGroupSpanishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getGroupSpanishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
      },
    };
    setAddProductFeatureModal(false);
    setDeleteUploadedFile("DeleteFile");
    setFileUploaded("");
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset());
    dispatch(updateGroupProductFeaturesHandler(payload));
  } else if (manageSwitch === true) {
    let payload = {
      id: getGroupProductFeatureContent?.data?.data?.id,
      content: {
        coi: false,
        isAllState: true,
        english: {
          coi: {
            state: getGroupEnglishFileUpload?.url
              ? [
                  {
                    url: getGroupEnglishFileUpload?.url,
                    fileName: getGroupEnglishFileUpload?.fileName,
                    stateName: selectedCheckbox === "allStates" ? true : [],
                    preSigned: getGroupEnglishFileUpload?.preSigned,
                  },
                ]
              : [],
          },
        },
        spanish: {
          coi: {
            state: [],
          },
        },
      },
    };
    setAddProductFeatureModal(false);
    setDeleteUploadedFile("DeleteFile");
    setSpanishFileUpload("");
    dispatch(fileUploadAction.fileUploadInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset());
    dispatch(updateGroupProductFeaturesHandler(payload));
  }
};
