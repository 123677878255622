import React, { useContext, useEffect, useState } from "react";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import CommonTable from "../../common/CommonTable";
import { reportColumns } from "./reportsUtils";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../common/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { reportsListHandler } from "../../redux/action/reportsSlice";
import Loader from "../../components/Loder/loader";

const Report = () => {
  const navigate = useNavigate();
  const reportColumanData = reportColumns(navigate);
  const dispatch = useDispatch();
  const { brandId } = useContext(ThemeContext);

  const [reportList,setReportList] = useState([])

  const reportsListSelector = useSelector((state)=>state?.reportsListDetails)

  const reportListing = () => {
    window.scrollTo(0, 0);
    const payload = {  brandId: 2 };
      dispatch(reportsListHandler(payload));
  };

  useEffect(() => {
    reportListing()
  }, [brandId])


  useEffect(() => {
    const data = reportsListSelector?.data?.map((item)=>{
      return{
        reportName:item?.reportName,
        fileData:item?.fileData
      }
    })
    setReportList(data)
  }, [reportsListSelector])

  return (
    <>
      {reportsListSelector?.isLoading === true  && <Loader loaderTransform="loaderTransform" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Reports</strong>
          </p>
          <CustomBreadcrumb
            items={[{ text: "Home" }, { text: "Reports", active: true }]}
          />

          <div className="table-padding">
            <div className="headtitle">
              <p className="mb-0 heedprodct">Reports</p>
            </div>
            <hr className="m-0" />
            <div className="table-responsive">
              <CommonTable
                body={reportList}
                columns={reportColumanData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
