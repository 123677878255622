import { createSlice } from "@reduxjs/toolkit";
import { updateNotificationsAPI } from "../../services/updateNotifications";

const data = {
    isLoading: false,
    error: "",
    message: "",
    data: null,
};

const updateNotificationsSlice = createSlice({
    name: "updateNotificationsSlice",
    initialState: data,
    reducers: {
        updateNotificationsInfo(state) {
            state.isLoading = true;
        },
        updateNotificationsInfoSuccess(state, action) {
            state.isLoading = false;
            state.data = action.payload;
            state.message = "";
        },
        updateNotificationsInfoFailure(state, action) {
            state.isLoading = false;
            state.message = action.payload;
            state.data = null;
        },
        updateNotificationsInfoReset(state) {
            state.isLoading = false;
            state.message = "";
            state.data = null;
            state.error = "";
        },
    },
});

export const updateNotificationsHandler = (data) => async (dispatch) => {
    try {
        dispatch(updateNotificationsAction.updateNotificationsInfo());
        const response = await updateNotificationsAPI(data);
        dispatch(updateNotificationsAction.updateNotificationsInfoSuccess(response));
        // localStorage.setItem("token",response?.data?.token)
    } catch (e) {
        dispatch(updateNotificationsAction.updateNotificationsInfoFailure(e));
    }
};
export default updateNotificationsSlice.reducer;
export const updateNotificationsAction = updateNotificationsSlice.actions;
